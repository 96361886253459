import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { NpPathResolver } from "./np-path-resolver";
import { NpPermission } from "./np-permission";
import { NpUserLoginService } from "./np-user-login-service";
import { NpPermissionUtils } from "./np-permission-utils";

@Injectable()
export class NpAuthChildGuard implements CanActivateChild {

    protected PERMISSION_PROPERTY: string = 'permission';

    constructor(private router: Router, private npUserLoginService: NpUserLoginService, private pathResolver: NpPathResolver) {
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

        // 로그인 서비스 초기화 체크 및 초기화
        if (this.npUserLoginService.isInitialized() === false) {
            await this.npUserLoginService.initialize();
        }

        const permission: NpPermission | null = this.getPermission(childRoute);

        let canAccess: boolean = NpPermissionUtils.canAccess(permission, this.npUserLoginService.isLoggedIn(), this.npUserLoginService.getAuthorities());

        if (canAccess === false) {
            if (this.npUserLoginService.isLoggedIn() == false) {

                let returnUrl = state.url ? '?returnUrl=' + state.url : ''
                const loginUrlTree: UrlTree = this.router.parseUrl(this.pathResolver.LOGIN_PATH + returnUrl);
                return loginUrlTree;
                // this.router.navigate([this.pathResolver.LOGIN_PATH]);
                // console.log(this.router);
                // this.router.navigateByUrl(this.pathResolver.LOGIN_PATH, { state: { fromUrl: this.router.url } });
            } else {
                this.router.navigate([this.pathResolver.FORBIDDEN_PATH]);
            }
        }

        return canAccess;


        // console.log(childRoute.url.toString() || 'empty', childRoute.data);
        // const permission = childRoute?.data?.[this.PERMISSION_PROPERTY];


        // to url
        // state.url

        // console.log('isLoggedIn', this.npUserLoginService.isLoggedIn());
        // console.log('getAuthorities', this.npUserLoginService.getAuthorities());

        return this.canAccessPromise(permission);
    }

    /**
     * 라우터가 paramsInheritanceStrategy: 'always' 옵션을 사용하지 않고도 
     * 현재 또는 현재보다 가장 가까운 상위의 permission을 가지고 오도록 함.
     * @param childRoute 
     * @returns 
     */
    private getPermission(childRoute: ActivatedRouteSnapshot): NpPermission | null {

        let permission = childRoute?.data?.[this.PERMISSION_PROPERTY];

        if (permission) {
            return permission;
        } else if (childRoute.parent) {
            return this.getPermission(childRoute.parent);
        } else {
            return null;
        }

    }


    async canAccessPromise(permission: NpPermission | null): Promise<boolean | UrlTree> {

        // 로그인 서비스 초기화 체크 및 초기화
        if (this.npUserLoginService.isInitialized() === false) {
            await this.npUserLoginService.initialize();
        }

        let canAccess: boolean = NpPermissionUtils.canAccess(permission, this.npUserLoginService.isLoggedIn(), this.npUserLoginService.getAuthorities());

        if (canAccess === false) {
            if (this.npUserLoginService.isLoggedIn() == false) {
                // this.router.navigate([this.pathResolver.LOGIN_PATH]);
                // console.log(this.router);
                // this.router.navigateByUrl(this.pathResolver.LOGIN_PATH, { state: { fromUrl: this.router.url } });
            } else {
                this.router.navigate([this.pathResolver.FORBIDDEN_PATH]);
            }
        }

        return canAccess;

    }

}