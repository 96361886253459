export class NpPermission {

    authorized!: boolean;
    allow!: string[];
    deny!: string[];

    constructor(authorized: boolean, allow?: string[] | null, deny?: string[] | null) {
        this.authorized = authorized;
        this.allow = allow || [];
        this.deny = deny || [];
    }
}

