import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

// @Directive()
@Component({ template: '' })
export abstract class NpBaseComponent implements OnInit, OnDestroy {

    ngOnInited: boolean = false;

    protected _subscriptions: Subscription[] = [];
    protected _ngOnInitTasks: Array<PromiseLike<any>> = [];

    protected ngOnInitError: (err: any) => void = err => {
        alert("페이지 초기화를 실패했습니다.");
    };

    ngOnInit(): void {

        let ngOnInited$: Promise<any>;

        if (this._ngOnInitTasks.length > 0) {
            ngOnInited$ = Promise.all(this._ngOnInitTasks);
        } else {
            ngOnInited$ = Promise.resolve();
        }

        ngOnInited$.then(() => {
            this.ngOnInited = true;
            this.npOnInit();
        }).catch(err => {
            this.ngOnInitError(err);
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((sb) => sb.unsubscribe());
    }

    // 페이지 초기화 여부
    abstract npOnInit(): void;

}