import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Error403Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
