<section class="wrap-login" *ngIf="false">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col" style="max-width: 500px;">
                <div class="card border-light p-5 shadow">
                    <form #ngForm1="ngForm" (ngSubmit)="ngForm1.valid && ngForm1Submit()">
                        <header class="text-center my-5">
                            <div class="text-center">
                                <h3>BMW 부품 배송관리</h3>
                            </div>
                        </header>
                        <div>
                            <div class="form-group mb-3" npFcvClass="loginId">
                                <label class="np-fc-label" for="form1_loginId">아이디</label>
                                <input type="text" class="form-control" placeholder="아이디를 입력하세요." name="loginId"
                                    [(ngModel)]="data.accountName" id="form1_loginId" required>
                                <div class="np-invalid-feedback">
                                    * 아이디를 입력하세요.
                                </div>
                            </div>
                            <div class="form-group mb-3" npFcvClass="loginPw">
                                <label class="np-fc-label" for="form1_loginPw">비밀번호</label>
                                <input type="password" id="form1_loginPw" class="form-control"
                                    placeholder="비밀번호를 입력하세요." name="loginPw" [(ngModel)]="data.accountPasswd" required>
                                <div class="np-invalid-feedback">
                                    * 비밀번호를 입력하세요.
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" name="rememberMe" [(ngModel)]="data.rememberMe"> Remember
                                        me
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="submit" class="btn btn-primary" [disabled]="!ngForm1.valid">로그인</button>
                            </div>
                        </div>
                        <!-- End Form -->
                        <footer class="text-center">
                        </footer>
                    </form>
                </div>
                <!-- <p class="mt-5 mb-3 text-muted">&copy; 2019</p> -->
            </div>
        </div>
    </div>
</section>
<div class="npx-login-bg" *ngIf="true">
    <div class="login-header-section"></div>
    <div class="login-body-section p-3">
        <div class="npx-login-form-wrapper rounded">
            <form class="npx-login-form" #ngForm1="ngForm" (ngSubmit)="ngForm1.valid && ngForm1Submit()">
                <!-- <img class="mb-4 logo" src="/assets/np-logo-black.png" style="text-align: center;display: block;"> -->
                <div class="text-center mt-4 mb-4">
                    <h3>신화로직스 운송사업부 TMS</h3>
                </div>
                <div class="form-floating mt-3">
                    <input type="email" class="form-control" id="floatingInput" name="accountName"
                        [(ngModel)]="data.accountName" required placeholder="name@example.com">
                    <label for="floatingInput">ID</label>
                </div>
                <div class="form-floating mt-3">
                    <input type="password" class="form-control" id="floatingPassword" name="accountPasswd"
                        [(ngModel)]="data.accountPasswd" required placeholder="Password">
                    <label for="floatingPassword">PASSWORD</label>
                </div>
                <div class="checkbox mt-3 mb-3">
                    <label>
                        <input type="checkbox" name="rememberMe" [(ngModel)]="data.rememberMe"> Remember me
                    </label>
                </div>

                <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                <!-- <p class="mt-5 mb-3 text-muted">&copy; New Planet 2018–2023</p> -->
            </form>
        </div>
    </div>
    <div class="login-footer-section">
    </div>
</div>
<div class="login-wrapper" *ngIf="false">

    <form class="form-signin w-100 m-auto" #ngForm1="ngForm" (ngSubmit)="ngForm1.valid && ngForm1Submit()">
        <img class="mb-4 logo" src="/assets/np-logo-black.png" style="text-align: center;display: block;">
        <div class="form-floating">
            <input type="email" class="form-control" id="floatingInput" name="accountName"
                [(ngModel)]="data.accountName" required placeholder="name@example.com">
            <label for="floatingInput">ID</label>
        </div>
        <div class="form-floating">
            <input type="password" class="form-control" id="floatingPassword" name="accountPasswd"
                [(ngModel)]="data.accountPasswd" required placeholder="Password">
            <label for="floatingPassword">PASSWORD</label>
        </div>
        <div class="checkbox mb-3">
            <label>
                <input type="checkbox" name="rememberMe" [(ngModel)]="data.rememberMe"> Remember me
            </label>
        </div>

        <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
        <p class="mt-5 mb-3 text-muted">&copy; New Planet 2018–2023</p>
    </form>
</div>

<div class="login-container" *ngIf="false">
    <div class="login-header-section"></div>
    <div class="login-body-section">
        <div class="login-msg">
            <div>
                <div class="login-msg-title"></div>
                <div class="login-msg-desc"></div>
            </div>
        </div>
        <div class="login-tab-panel">
            <div data-v-c58ee6e4="" class="tab-panel login-tabs only-one-tab">
                <div data-v-c58ee6e4="" class="tab-mask-wrapper spinner"><svg data-v-c58ee6e4="" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg" class="login-spinner tab-mask-center tab-mask-spinner">
                        <g class="login-spinner-g">
                            <circle cx="33" cy="33" stroke-width="5" r="30.5" class="login-spinner-circle"></circle>
                        </g>
                    </svg></div>
                <div data-v-c58ee6e4="" class="tab-mask-wrapper" style="display: none;" wfd-invisible="true">
                    <div data-v-c58ee6e4="" class="tab-mask-center">
                        <div data-v-70c0f15f="" data-v-c58ee6e4="" class="bomber"></div>
                    </div>
                </div>
                <div data-v-c58ee6e4="" class="tab-wrapper">
                    <div data-v-c58ee6e4="" class="tab-label-ct"><span data-v-c58ee6e4="" class="tab-label active">DSM에
                            로그인</span></div>
                    <div data-v-c58ee6e4="" class="tab-content-ct">
                        <div class="tab dsm-login-panel-tab" data-v-c58ee6e4="">
                            <div>
                                <div class="field login-title"><!----> <!----> <span>newpla-nas</span></div>
                            </div>
                            <div class="login-tabs-content-wrapper login-content-section" route-prefix="/signin">
                                <div class="tab-panel-title">
                                    로그인
                                </div>
                                <div class="login-fieldset">
                                    <form id="dsm-user-fieldset">
                                        <div class="login-textfield-wrapper field">
                                            <div class="login-textfield"><span class=""></span>
                                                <div class="input-container"><input syno-id="username" type="text"
                                                        placeholder="사용자 이름" enterkeyhint="" autofocus="autofocus"
                                                        name="username" autocomplete="username" tabindex="1" class="">
                                                </div>
                                                <div class="border active-border"></div>
                                                <div class="border bottom-border"></div>
                                                <div class="border error-border"></div>
                                            </div> <!---->
                                        </div> <input type="password" name="password" tabindex="-1" hidden="hidden">
                                    </form>
                                </div>
                                <div role="button" tabindex="2" syno-id="account-panel-next-btn" aria-label="로그인"
                                    class="login-btn">
                                    <div class="login-btn-spinner-wrapper"><svg viewBox="0 0 66 66"
                                            xmlns="http://www.w3.org/2000/svg" class="login-spinner login-btn-spinner">
                                            <g class="login-spinner-g">
                                                <circle cx="33" cy="33" stroke-width="5" r="30.5"
                                                    class="login-spinner-circle"></circle>
                                            </g>
                                        </svg></div>
                                    <div class="login-btn-animation animation-active"></div>
                                    <div class="grow-bg"
                                        style="height: 2068px; width: 2068px; top: -998px; left: -998px; transform: scale(0.0174081);">
                                    </div>
                                    <div class="arrow"></div>
                                </div>
                            </div>
                        </div> <!----> <!---->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="login-footer-section">
        <div data-v-6c4d8baa="" class="footer">
            <div data-v-6c4d8baa="" class="box-wrapper">
                <div data-v-6c4d8baa="" class="box" style="max-height: 57px;">
                    <div data-v-6c4d8baa="" class="msg"></div>
                </div>
            </div>
        </div>
    </div>
</div>