<div class="splash-screen" [@splashScreen] *ngIf="!(appLoader.appInited | async)">
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg>
</div>

<div class="splash-screen clean" [@splashScreen] *ngIf="(appLoader.loading | async) ">
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg>
</div>

<div class="splash-screen page-load" [@splashScreen] *ngIf="(appLoader.pageLoading | async)">
  <div class="spinner3">
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
  </div>
  <!-- <div class="spinner2">
    <i class="fa-solid fa-spinner" style="font-size: 3rem;"></i>
  </div> -->
  <!-- <svg class="spinner2" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg> -->
</div>