<div id="info-wrapper">
    <div class="content">
        <div class="content-header">
            <h1>404</h1>
        </div>
        <h2>Oops! This Page Could Not Be Found</h2>
        <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily
            unavailable</p>
        <a href="/">Go Home</a>
    </div>
</div>