import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NewplaFormsModule, NpErrorUtils } from '@newpla/common';
import { CommonModule } from '@angular/common';
import { NpxLoginService } from '../../../../../newpla-npx-ng17/src/_core/auth/npx-login-service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, NewplaFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

  data = { accountName: '', accountPasswd: '', rememberMe: false };
  redirectUrl!: string;

  constructor(private route: ActivatedRoute, private router: Router, private loginService: NpxLoginService) {
    // http://localhost:4200/app/demo/newpla-libs/fullpage/usage
    // const navigation = this.router.getCurrentNavigation();
    // const state = navigation?.extras?.state;

    const queryParams = this.route.snapshot.queryParams;
    if (queryParams?.['returnUrl']) {
      this.redirectUrl = queryParams['returnUrl'];
    }

  }

  ngOnInit(): void {
  }

  async ngForm1Submit() {
    await this.loginService.login(this.data).then(success => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
      } else {
        this.router.navigate(['/']);
      }
    }
    ).catch(reason => {
      NpErrorUtils.alert(reason);
    });
  }

}
