import { LOCALE_ID, Provider } from "@angular/core";
import { NpLocaleService } from "./np-locale.service";

export class NpLocaleId extends String {

    constructor(private npLocaleService: NpLocaleService) {
        super();
    }

    override toString(): string {
        return this.npLocaleService.localeId;
    }

    override valueOf(): string {
        return this.npLocaleService.localeId;
    }

}

export const NpLocaleProvider: Provider = { provide: LOCALE_ID, useClass: NpLocaleId, deps: [NpLocaleService] };