import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NpPathResolver, NpUserLoginService } from "@newpla/common";
import { CookieService } from "ngx-cookie-service";
import { lastValueFrom, map } from "rxjs";
import { NpxJwtUtils } from "./npx-jwt-utils";

@Injectable()
export class NpxLoginService extends NpUserLoginService {

    JWT_COOKIE_NAME = 'NPX-X-TOKEN';

    ACCOUNT_API_PATH = {
        LOGIN: '/api/npx/account/login',
        LOGIN_DATA: '/api/npx/account/loginData',
        LOG_OUT: '/api/npx/account/logout'
    };

    constructor(private router: Router, private npPathResolver: NpPathResolver, private httpClient: HttpClient, private cookieService: CookieService) {
        super()
    }

    private inited: boolean = false;

    isInitialized(): boolean {
        return this.inited;
    }

    initialize(): Promise<boolean> {

        // JWT 인증 검사
        if (this.cookieService.check(this.JWT_COOKIE_NAME)) {
            try {
                let token = this.cookieService.get(this.JWT_COOKIE_NAME);
                let jwtJson = NpxJwtUtils.decode(token);
                const now = (new Date()).getTime();
                let expired = jwtJson.exp * 1000 < now;
                if (expired) {
                    throw new Error('jwt expired')
                }
                this._dataBs.next(jwtJson.data);
                return Promise.resolve(true);
            } catch (err) {
            }

        }
        // JWT가 없다면 세션 검사
        let req = this.httpClient.get<any>(this.ACCOUNT_API_PATH.LOGIN_DATA).pipe(map(res => {
            if (res) {
                this._dataBs.next(res);
            }
            this.inited = true;
            return true;
        }));
        return lastValueFrom(req).catch(res => { return true; });
    }

    isLoggedIn(): boolean {
        return this.getLoginData() !== null;
    }

    logout() {
        lastValueFrom(this.httpClient.get(this.ACCOUNT_API_PATH.LOG_OUT)).then(res => {
            this.clear();
            window.location.href = "/";
        });

        // this.router.navigate(['/']);
    }
    getLoginData() {
        const loginData = this._dataBs.value;
        if (loginData) {
            return loginData;
        } else {
            return null;
        }
    }
    getAuthorities(): string[] {
        return this.getLoginData()?.authorities;
    }

    login(data: { accountName: string; accountPasswd: string; rememberMe: boolean }): Promise<any> {

        let loginData = { loginId: data.accountName, loginPw: data.accountPasswd, rememberMe: data.rememberMe };

        let req = this.httpClient.post(this.ACCOUNT_API_PATH.LOGIN, loginData).pipe(map(res => {
            this._dataBs.next(res);
            return true;
        }))

        return lastValueFrom(req);
    }

    clear(): void {
        this._dataBs.next(null);
        this.cookieService.delete(this.JWT_COOKIE_NAME);
    }

}