import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { NpAppLayoutService, NpAuthChildGuard, NpBreadcrumbService, NpPathResolver, NpUserLoginService } from '@newpla/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppPathResolver } from './_app/config/app-path-resolver';
import { NpxLoginService } from '../../../newpla-npx-ng17/src/_core/auth/npx-login-service';
import { NpLocaleService } from '../../../newpla-npx-ng17/src/_core/locale/np-locale.service';
import { NpLocaleProvider } from '../../../newpla-npx-ng17/src/_core/locale/np-locale-provider';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

import "@grapecity/wijmo.cultures/wijmo.culture.ko";
import * as wjcCore from '@grapecity/wijmo';

import * as ej2Base from '@syncfusion/ej2-base';
import { EJ2_LOCALE_KO } from '@newpla/ej2';

import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';

import { environment } from '../environments/environment';
import { NpxHttpAuthInterceptor } from '../../../newpla-npx-ng17/src/_core/auth/npx-http-auth-interceptor.service';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';


// wijmo
wjcCore.setLicenseKey(environment.wijmoLicenseKey);
wjcCore.changeCulture('ko');
// ej2
ej2Base.registerLicense(environment.syncfusionEj2LicenseKey);;
ej2Base.L10n.load({ ko: EJ2_LOCALE_KO.ko });
ej2Base.setCulture("ko");

registerLocaleData(localeKo);

function initializeApp(npUserLoginService: NpUserLoginService) {
  return async () => {

    await npUserLoginService.initialize().then(res => {
      console.log('initializeApp');
    })
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    //
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    // withFetch()
    // Newpla Default
    NpAuthChildGuard, AppPathResolver, NpxLoginService,
    // APP_LAY_OUT
    NpAppLayoutService, NpBreadcrumbService,
    { provide: NpPathResolver, useExisting: AppPathResolver },
    { provide: NpUserLoginService, useExisting: NpxLoginService },
    // LOCALE
    NpLocaleService, NpLocaleProvider,
    // APP CONFIG
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [NpUserLoginService] },
    { provide: HTTP_INTERCEPTORS, useClass: NpxHttpAuthInterceptor, multi: true },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    provideRouter(routes),

  ]
};
