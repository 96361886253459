import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { LANGUAGE_COOKIE_NAME, TIMEZONE_COOKIE_NAME, LOCALE_COOKIE_NAME } from './npx-locale-cookies';
import { NpCookieUtils } from '../../../../../newpla-modules-ng17/code_newpla';

const TIME = 1000 * 60 * 60 * 24;

@Injectable()
export class NpLocaleService {

  private _localeId: string;
  private _timeZone: string;
  // private _timeZone: string;

  constructor(
    @Optional()
    @SkipSelf()
    otherInstance: NpLocaleService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (otherInstance) throw 'LocaleService should have only one instance.';

    const cookieLang = NpCookieUtils.getCookie(LANGUAGE_COOKIE_NAME);
    const browserLang = navigator.language;

    let lang = cookieLang ? cookieLang : browserLang;
    this._localeId = lang;
    this._timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    this.updateCookies();
  }

  setLocale(localeId: string) {
    this._localeId = localeId;
    this.updateCookies();
  }

  get localeId(): string {
    return this._localeId.toString();
  }

  setTimeZone(timeZone: string) {
    this._timeZone = timeZone;
    this.updateCookies()

  }

  get timeZone(): string {
    return this._timeZone;
  }

  updateCookies() {

    if (this.timeZone) {
      NpCookieUtils.setCookie(TIMEZONE_COOKIE_NAME, this.timeZone, TIME);
    }

    if (this.localeId) {
      this.document.documentElement.lang = this.localeId;
      NpCookieUtils.setCookie(LANGUAGE_COOKIE_NAME, this.localeId, TIME);

      let clientLocale = this.localeId;
      if (this.timeZone) {
        clientLocale += '/' + this.timeZone;
      }

      NpCookieUtils.setCookie(LOCALE_COOKIE_NAME, clientLocale, TIME);
    } else {
      NpCookieUtils.deleteCookie(LOCALE_COOKIE_NAME);
      NpCookieUtils.deleteCookie(LANGUAGE_COOKIE_NAME);
    }

  }

}
