import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { splashScreenAni } from './splash-screen.animations';
import { CommonModule } from '@angular/common';
import { NpAppLayoutService } from '@newpla/common';

@Component({
  selector: 'splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  standalone: true,
  imports: [CommonModule],
  animations: [splashScreenAni],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit, OnDestroy {

  appLoader;

  constructor(private npAppLayoutService: NpAppLayoutService) {
    this.appLoader = npAppLayoutService.appLoader;
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }

}
