import { Routes } from '@angular/router';
import { NpAuthChildGuard, NpPermission } from '@newpla/common';
import { Error403Component } from './app-default-pages/error/error403/error403.component';
import { Error404Component } from './app-default-pages/error/error404/error404.component';
import { LoginComponent } from './app-default-pages/login/login.component';

export const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'error/403', component: Error403Component },
  { path: 'error/404', component: Error404Component },
  {
    path: 'app', loadChildren: () => import('./app-main/app-main.module').then(m => m.AppMainModule),
    data: { permission: new NpPermission(true, [], []) }, canActivateChild: [NpAuthChildGuard]
  },
  {
    // 거래처 DCS 조회 PATH
    path: 'intf', loadChildren: () => import('./app-intf/app-intf-routes').then(m => m.routes)
  },
  {
    path: 'npx/admin', loadChildren: () => import('../../../newpla-npx-ng17/src/npx-app-admin/npx-app-admin.routes').then(m => m.npxAppAdminRoutes),
    data: { permission: new NpPermission(true, [], []) }, canActivateChild: [NpAuthChildGuard]
  },
  // # dev, demo, test ###########
  {
    path: 'npx/test', loadChildren: () => import('../../../newpla-npx-ng17/src/npx-app-test/npx-test.routes').then(m => m.routes)
  },
  // {
  //   path: 'npx/sysadmin', loadChildren: () => import('../../../newpla-npx-ng17/src/npx-app-sysadmin/npx-sysadmin.routes').then(m => m.npxSysadminRoutes)
  // },
  // {
  //   path: 'newpla-demo', loadChildren: () => import('projects/newpla-demo/src/app/newpla-demo/newpla-demo.module').then(m => m.NewplaDemoModule),
  // },
  { path: '**', redirectTo: 'error/404' }
];

// const routes: Routes = [
//     { path: '', redirectTo: 'app', pathMatch: 'full' },
//     { path: 'login', component: LoginComponent },
//     {
//       path: 'error', children: [
//         { path: '403', component: Error403Component },
//         { path: '404', component: Error404Component }
//       ]
//     },
//     {
//       // path: 'app', loadChildren: () => import('../app-main/app-main.module').then(m => m.AppMainModule),
//       data: { permission: new NpPermission(true, [], []) }, canActivateChild: [NpAuthChildGuard]
//     },
//     { path: '**', redirectTo: 'error/404' }
//   ];