import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Http401Error, NpPathResolver, NpUserLoginService } from "@newpla/common";
import { catchError, Observable } from "rxjs";
import { LANGUAGE_COOKIE_NAME } from "../locale/npx-locale-cookies";
import { NpCookieUtils } from "../../../../../newpla-modules-ng17/code_newpla";

@Injectable()
export class NpxHttpAuthInterceptor implements HttpInterceptor {

    constructor(
        private userLoginService: NpUserLoginService,
        private pathResolver: NpPathResolver,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const language = NpCookieUtils.getCookie(LANGUAGE_COOKIE_NAME);

        if (language) {
            let header: { [name: string]: string | string[] } = {};
            header["Accept-Language"] = language;
            req = req.clone({
                setHeaders: header
            });
        }

        return next.handle(req).pipe(catchError((errorRes: HttpErrorResponse) => {

            if (errorRes.status === 504) {
                // 401 에러의 경우 인증정보를 초기화 한다.
                // this.router.navigate([this.pathResolverService.login]);
                // 504 ERROR: 
                throw new Error("접속장애가 발생하였습니다.\n시스템 관리자에게 문의하세요.");
            }

            if (errorRes.status === 401) {
                // 401 에러의 경우 인증정보를 초기화 한다.
                this.userLoginService.clear();
                this.router.navigateByUrl(this.pathResolver.LOGIN_PATH, { state: { fromUrl: this.router.url } });
                // console.log(error.status + ' ' + error.statusText);

                if (errorRes.error.message) {
                    throw new Http401Error(errorRes.error.message);
                } else {
                    throw new Http401Error(errorRes.status + ' ' + errorRes.statusText);
                }

                // this.router.navigate([this.pathResolverService.login]);
            }

            // 403은 권한이 없는 경우로 로그인이 되어 있는 상태에서 권한이 없음을 말한다.
            // 따라서 에러로 처리 하며 해당 페이지에서 관련 동작을 수행한다.
            // 참고1: ajax의 403은 서버에서 로그인은 되었지만 권한이 없음을 말한다. 따라서 이전처럼 403 페이지로 보내지 않는다.
            // 참고2: 서버에서의 403은 페이지 라우팅에서의 403과 완전히 별도이다.
            // if (error.status === 403) {
            //     // 403 에러가 발생한 경우 현재 사용자의 로그인 여부에 따라 동작한다.
            //     if (this.userLoginService.isLoggedIn()) {
            //         // 실제 서버에 로그인 되어 있는지 재검사
            //         this.userLoginService.initialize().then(res => {
            //             if (res) {
            //                 // 로그인 되어 있다면 403 에러 페이지로
            //                 this.router.navigateByUrl(this.pathResolver.FORBIDDEN_PATH);
            //                 // console.log(error.status + ' ' + error.statusText);
            //                 throw new NpxSkipAlertError(error.status + ' ' + error.statusText);
            //             } else {
            //                 // 로그인이 되어있지 않다면 로그인 페이지로
            //                 this.userLoginService.clear();
            //                 this.router.navigateByUrl(this.pathResolver.LOGIN_PATH);
            //                 // console.log(error.status + ' ' + error.statusText);
            //                 throw new NpxSkipAlertError(error.status + ' ' + error.statusText);
            //             }
            //         });
            //     } else {
            //         // 로그인이 되어 있지 않다면 로그인 페이지로 이동
            //         this.router.navigateByUrl(this.pathResolver.LOGIN_PATH);
            //         // console.log(error.status + ' ' + error.statusText);
            //         throw new NpxSkipAlertError(error.status + ' ' + error.statusText);
            //     }
            // }

            throw errorRes;
        }));

    }
}