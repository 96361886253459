export const EJ2_LOCALE_KO = {
    "ko": {
        "grid": {
            "EmptyRecord": "표시 할 레코드가 없습니다",
            "True": "진실",
            "False": "그릇된",
            "InvalidFilterMessage": "잘못된 필터 데이터",
            "GroupDropArea": "열 머리글을 여기로 끌어 열을 그룹화하십시오",
            "UnGroup": "그룹을 해제하려면 여기를 클릭하십시오",
            "GroupDisable": "이 열에 대해 그룹화가 비활성화되었습니다",
            "FilterbarTitle": "\"s 필터 막대 셀",
            "EmptyDataSourceError": "자동 생성 열 그리드의 dataSource에서 열이 생성되므로 초기로드시 DataSource를 비워 둘 수 없습니다.",
            "Add": "더하다",
            "Edit": "편집하다",
            "Cancel": "취소",
            "Update": "최신 정보",
            "Delete": "지우다",
            "Print": "인쇄",
            "Pdfexport": "PDF 내보내기",
            "Excelexport": "엑셀 내보내기",
            "Wordexport": "단어 내보내기",
            "Csvexport": "CSV 내보내기",
            "Search": "검색",
            "Columnchooser": "열",
            "Save": "저장",
            "Item": "안건",
            "Items": "아이템",
            "EditOperationAlert": "편집 조작을 위해 선택된 레코드가 없습니다",
            "DeleteOperationAlert": "삭제 조작을 위해 선택된 레코드가 없습니다.",
            "SaveButton": "저장",
            "OKButton": "확인",
            "CancelButton": "취소",
            "EditFormTitle": "세부 사항",
            "AddFormTitle": "새 레코드 추가",
            "BatchSaveConfirm": "변경 사항을 저장 하시겠습니까?",
            "BatchSaveLostChanges": "저장하지 않은 변경 사항은 손실됩니다. 정말로 계속하기를 원하십니까?",
            "ConfirmDelete": "레코드를 삭제 하시겠습니까?",
            "CancelEdit": "변경 사항을 취소 하시겠습니까?",
            "ChooseColumns": "열을 선택하십시오",
            "SearchColumns": "검색 열",
            "Matchs": "검색 결과가 없습니다",
            "FilterButton": "필터",
            "ClearButton": "명확한",
            "StartsWith": "로 시작",
            "EndsWith": "로 끝나다",
            "Contains": "포함",
            "Equal": "같은",
            "NotEqual": "같지 않음",
            "LessThan": "보다 작음",
            "LessThanOrEqual": "작거나 같음",
            "GreaterThan": "보다 큰",
            "GreaterThanOrEqual": "크거나 같음",
            "ChooseDate": "날짜를 선택하십시오",
            "EnterValue": "값을 입력하십시오",
            "Copy": "부",
            "Group": "이 열을 기준으로 그룹화",
            "Ungroup": "이 항목을 기준으로 그룹 해제",
            "autoFitAll": "모든 열 자동 맞춤",
            "autoFit": "이 열 자동 맞춤",
            "Export": "수출",
            "FirstPage": "첫 페이지",
            "LastPage": "마지막 페이지",
            "PreviousPage": "이전 페이지",
            "NextPage": "다음 페이지",
            "SortAscending": "오름차순 정렬",
            "SortDescending": "내림차순 정렬",
            "EditRecord": "레코드 편집",
            "DeleteRecord": "레코드 삭제",
            "FilterMenu": "필터",
            "SelectAll": "모두 선택",
            "Blanks": "공백",
            "FilterTrue": "진실",
            "FilterFalse": "그릇된",
            "NoResult": "검색 결과가 없습니다",
            "ClearFilter": "필터 지우기",
            "NumberFilter": "숫자 필터",
            "TextFilter": "텍스트 필터",
            "DateFilter": "날짜 필터",
            "DateTimeFilter": "날짜 / 시간 필터",
            "MatchCase": "경기 사례",
            "Between": "중에서",
            "CustomFilter": "맞춤 필터",
            "CustomFilterPlaceHolder": "값을 입력하십시오",
            "CustomFilterDatePlaceHolder": "날짜를 선택하십시오",
            "AND": "과",
            "OR": "또는",
            "ShowRowsWhere": "다음 위치에 행을 표시하십시오.",
            "NotStartsWith": "~로 시작하지 않는다",
            "Like": "좋다",
            "NotEndsWith": "로 끝나지 않는다",
            "NotContains": "포함되어 있지 않다",
            "IsNull": "없는",
            "NotNull": "null이 아님",
            "IsEmpty": "비어 있는",
            "IsNotEmpty": "비어있지 않다",
            "AddCurrentSelection": "필터에 현재 선택 항목 추가",
            "UnGroupButton": "그룹을 해제하려면 여기를 클릭하세요.",
            "AutoFitAll": "모든 열 자동 맞춤",
            "AutoFit": "이 열 자동 맞춤",
            "Clear": "맑은",
            "FilterMenuDialogARIA": "필터 메뉴 대화 상자",
            "ExcelFilterDialogARIA": "Excel 필터 대화 상자",
            "DialogEditARIA": "편집 대화 상자",
            "ColumnChooserDialogARIA": "열 선택기",
            "ColumnMenuDialogARIA": "열 메뉴 대화 상자",
            "CustomFilterDialogARIA": "사용자 정의 필터 대화 상자",
            "SortAtoZ": "A부터 Z까지 정렬",
            "SortZtoA": "Z에서 A로 정렬",
            "SortByOldest": "오래된 순으로 정렬",
            "SortByNewest": "최신순으로 정렬",
            "SortSmallestToLargest": "작은 것부터 큰 것 순으로 정렬",
            "SortLargestToSmallest": "큰 것부터 작은 것 순으로 정렬",
            "Sort": "활자 한 벌",
            "FilterDescription": "Alt Down을 눌러 필터 메뉴 열기",
            "SortDescription": "Enter 키를 눌러 정렬",
            "ColumnMenuDescription": "Alt Down 키를 눌러 열 메뉴 열기",
            "GroupDescription": "Ctrl 스페이스를 눌러 그룹화",
            "ColumnHeader": " 열 머리글 ",
            "TemplateCell": " 템플릿 셀입니다",
            "CommandColumnAria": "명령 열 열 머리글입니다. ",
            "DialogEdit": "대화 편집",
            "ClipBoard": "클립보드",
            "GroupButton": "그룹 버튼",
            "UnGroupAria": "그룹 해제 버튼",
            "GroupSeperator": "그룹화된 열의 구분 기호",
            "UnGroupIcon": "그룹화된 열 그룹 해제 ",
            "GroupedSortIcon": "그룹화된 열 정렬 ",
            "GroupedDrag": "그룹화된 열 드래그",
            "GroupCaption": " 그룹 캡션 셀입니다",
            "CheckBoxLabel": "체크박스",
            "Expanded": "퍼지는",
            "Collapsed": "축소됨"
        },
        "pager": {
            "currentPageInfo": "{1} 페이지 중 {0}",
            "totalItemsInfo": "({0} 항목)",
            "firstPageTooltip": "첫 페이지로 이동",
            "lastPageTooltip": "마지막 페이지로 이동",
            "nextPageTooltip": "다음 페이지로 이동",
            "previousPageTooltip": "이전 페이지로 이동",
            "nextPagerTooltip": "다음 호출기 항목으로 이동",
            "previousPagerTooltip": "이전 호출기 항목으로 이동",
            "pagerDropDown": "페이지 당 항목",
            "pagerAllDropDown": "아이템",
            "All": "모두",
            "totalItemInfo": "({0} 항목)",
            "Container": "호출기 컨테이너",
            "Information": "호출기 정보",
            "ExternalMsg": "호출기 외부 메시지",
            "Page": "페이지 ",
            "Of": " 의 ",
            "Pages": " 페이지"
        },
        "kanban": {
            "items": "아이템",
            "min": "최소",
            "max": "맥스",
            "cardsSelected": "선택된 카드",
            "addTitle": "새 카드 추가",
            "editTitle": "카드 정보 편집",
            "deleteTitle": "카드 삭제",
            "deleteContent": "이 카드를 삭제 하시겠습니까?",
            "save": "저장",
            "delete": "지우다",
            "cancel": "취소",
            "yes": "예",
            "no": "아니",
            "close": "닫기",
            "noCard": "표시 할 카드가 없습니다.",
            "unassigned": "할당되지 않음"
        },
        "dropdowns": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패",
            "overflowCountTemplate": "+${count} 개 더 ..",
            "selectAllText": "모두 선택",
            "unSelectAllText": "모두 선택 해제",
            "totalCountTemplate": "선택된 ${count}"
        },
        "drop-down-list": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패"
        },
        "combo-box": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패"
        },
        "auto-complete": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패"
        },
        "multi-select": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패",
            "overflowCountTemplate": "+${count} 개 더 ..",
            "selectAllText": "모두 선택",
            "unSelectAllText": "모두 선택 해제",
            "totalCountTemplate": "선택된 ${count}"
        },
        "listbox": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패",
            "selectAllText": "모두 선택",
            "unSelectAllText": "모두 선택 해제",
            "moveUp": "이동",
            "moveDown": "아래로 이동",
            "moveTo": "이동",
            "moveFrom": "에서왔다",
            "moveAllTo": "모두로 이동",
            "moveAllFrom": "모두에서 이동"
        },
        "spreadsheet": {
            "InsertingEmptyValue": "참조 값이 유효하지 않습니다.",
            "FindValue": "가치 찾기",
            "ReplaceValue": "값 바꾸기",
            "FindReplaceTooltip": "찾기 및 바꾸기",
            "ByRow": "행별",
            "ByColumn": "열별",
            "MatchExactCellElements": "전체 셀 내용 일치",
            "EnterCellAddress": "셀 주소 입력",
            "FindAndReplace": "찾기 및 바꾸기",
            "ReplaceAllEnd": " 다음으로 교체된 경기",
            "FindNextBtn": "다음 찾기",
            "FindPreviousBtn": "이전 찾기",
            "ReplaceBtn": "바꾸다",
            "ReplaceAllBtn": "전부 교체",
            "GotoHeader": "이동",
            "Sheet": "시트",
            "SearchWithin": "내에서 검색",
            "SearchBy": "검색",
            "Reference": "참조",
            "Workbook": "학습장",
            "NoElements": "찾고 계신 것을 찾을 수 없습니다.",
            "FindWhat": "무엇을 찾다",
            "ReplaceWith": "교체",
            "FileName": "파일 이름",
            "ExtendValidation": "선택 항목에 데이터 유효성 검사가 없는 일부 셀이 포함되어 있습니다. 이러한 셀에 대한 유효성 검사를 확장하시겠습니까?",
            "Yes": "예",
            "No": "아니요",
            "PROPER": "텍스트를 적절한 대소문자로 변환합니다. 첫 글자는 대문자로 다른 글자는 소문자로.",
            "Cut": "자르다",
            "Copy": "복사",
            "Paste": "반죽",
            "PasteSpecial": "선택하여 붙여넣기",
            "All": "모두",
            "Values": "가치",
            "Formats": "형식",
            "Font": "폰트",
            "FontSize": "글꼴 크기",
            "Bold": "용감한",
            "Italic": "이탤릭체",
            "Underline": "밑줄",
            "Strikethrough": "취소선",
            "TextColor": "텍스트 색상",
            "FillColor": "채우기 색상",
            "HorizontalAlignment": "수평 정렬",
            "AlignLeft": "왼쪽 정렬",
            "AlignCenter": "센터",
            "AlignRight": "오른쪽 정렬",
            "VerticalAlignment": "수직 정렬",
            "AlignTop": "상단 정렬",
            "AlignMiddle": "중간 정렬",
            "AlignBottom": "하단 정렬",
            "MergeCells": "셀 병합",
            "MergeAll": "모두 병합",
            "MergeHorizontally": "수평으로 병합",
            "MergeVertically": "수직으로 병합",
            "Unmerge": "병합 해제",
            "UnmergeCells": "셀 병합 해제",
            "SelectMergeType": "병합 유형 선택",
            "MergeCellsAlert": "셀을 병합하면 맨 왼쪽 위(최상위) 값만 유지됩니다. 병합하시겠습니까?",
            "PasteMergeAlert": "병합 셀에는 그렇게 할 수 없습니다.",
            "Borders": "테두리",
            "TopBorders": "위쪽 테두리",
            "LeftBorders": "왼쪽 테두리",
            "RightBorders": "오른쪽 테두리",
            "BottomBorders": "아래쪽 테두리",
            "AllBorders": "모든 테두리",
            "HorizontalBorders": "가로 테두리",
            "VerticalBorders": "세로 테두리",
            "OutsideBorders": "외부 국경",
            "InsideBorders": "내부 테두리",
            "NoBorders": "국경 없음",
            "BorderColor": "테두리 색상",
            "BorderStyle": "테두리 스타일",
            "InsertFunction": "함수 삽입",
            "Insert": "끼워 넣다",
            "Delete": "삭제",
            "DuplicateSheet": "복제하다",
            "MoveRight": "오른쪽으로 이동해라",
            "MoveLeft": "왼쪽으로 이동",
            "Rename": "이름 바꾸기",
            "Hide": "숨다",
            "NameBox": "이름 상자",
            "ShowHeaders": "헤더 표시",
            "HideHeaders": "헤더 숨기기",
            "ShowGridLines": "눈금선 표시",
            "HideGridLines": "눈금선 숨기기",
            "FreezePanes": "틀 고정",
            "FreezeRows": "행 고정",
            "FreezeColumns": "열 고정",
            "UnfreezePanes": "창 고정 해제",
            "UnfreezeRows": "행 고정 해제",
            "UnfreezeColumns": "열 고정 해제",
            "AddSheet": "시트 추가",
            "ListAllSheets": "모든 시트 나열",
            "CollapseToolbar": "도구 모음 접기",
            "ExpandToolbar": "도구 모음 확장",
            "CollapseFormulaBar": "수식 입력줄 접기",
            "ExpandFormulaBar": "수식 입력줄 확장",
            "File": "파일",
            "Home": "집",
            "Formulas": "방식",
            "View": "보다",
            "New": "새로운",
            "Open": "열려 있는",
            "SaveAs": "다른 이름으로 저장",
            "ExcelXlsx": "마이크로 소프트 엑셀",
            "ExcelXls": "마이크로소프트 엑셀 97-2003",
            "CSV": "쉼표로 구분된 값",
            "FormulaBar": "수식 바",
            "Sort": "종류",
            "SortAscending": "오름차순",
            "SortDescending": "내림차순",
            "CustomSort": "맞춤 정렬",
            "AddColumn": "열 추가",
            "ContainsHeader": "데이터에 헤더 포함",
            "CaseSensitive": "대소문자 구분",
            "SortBy": "정렬 기준",
            "ThenBy": "그럼",
            "SelectAColumn": "열 선택",
            "SortEmptyFieldError": "모든 정렬 기준에는 지정된 열이 있어야 합니다. 선택한 정렬 기준을 확인하고 다시 시도하십시오.",
            "SortDuplicateFieldError": " 값별로 두 번 이상 정렬됩니다. 중복 정렬 기준을 삭제하고 다시 시도하십시오.",
            "SortOutOfRangeError": "사용된 범위 내의 셀 또는 범위를 선택하고 다시 시도하십시오.",
            "MultiRangeSortError": "다중 범위 선택에서는 이 작업을 수행할 수 없습니다. 단일 범위를 선택하고 다시 시도하십시오.",
            "HideRow": "행 숨기기",
            "HideRows": "행 숨기기",
            "UnhideRows": "행 숨기기 해제",
            "HideColumn": "열 숨기기",
            "HideColumns": "열 숨기기",
            "UnhideColumns": "열 숨기기 해제",
            "InsertRow": "행 삽입",
            "InsertRows": "행 삽입",
            "Above": "위에",
            "Below": "아래에",
            "InsertColumn": "열 삽입",
            "InsertColumns": "열 삽입",
            "Before": "전에",
            "After": "후에",
            "DeleteRow": "행 삭제",
            "DeleteRows": "행 삭제",
            "DeleteColumn": "열 삭제",
            "DeleteColumns": "열 삭제",
            "Ok": "확인",
            "Cancel": "취소",
            "Apply": "적용하다",
            "MoreColors": "더 많은 색상",
            "StandardColors": "표준 색상",
            "General": "일반적인",
            "Number": "숫자",
            "Currency": "통화",
            "Accounting": "회계",
            "ShortDate": "짧은 데이트",
            "LongDate": "긴 날짜",
            "Time": "시간",
            "Percentage": "백분율",
            "Fraction": "분수",
            "Scientific": "과학적",
            "Text": "텍스트",
            "NumberFormat": "숫자 형식",
            "MobileFormulaBarPlaceHolder": "값 또는 수식 입력",
            "PasteAlert": "복사 영역과 붙여넣기 영역이 같은 크기가 아니기 때문에 여기에 붙여넣을 수 없습니다. 다른 범위에서 붙여넣기를 시도하십시오.",
            "DestroyAlert": "저장하지 않고 현재 통합 문서를 삭제하고 새 통합 문서를 만드시겠습니까?",
            "SheetRenameInvalidAlert": "시트 이름에 잘못된 문자가 포함되어 있습니다.",
            "SheetRenameEmptyAlert": "시트 이름은 비워 둘 수 없습니다.",
            "SheetRenameAlreadyExistsAlert": "시트 이름이 이미 존재합니다. 다른 이름을 입력하세요.",
            "DeleteSheetAlert": "이 시트를 삭제하시겠습니까?",
            "DeleteSingleLastSheetAlert": "통합 문서에는 보이는 워크시트가 하나 이상 있어야 합니다.",
            "PickACategory": "카테고리 선택",
            "Description": "설명",
            "UnsupportedFile": "지원되지 않는 파일",
            "DataLimitExceeded": "파일 데이터가 너무 커서 처리하는 데 시간이 더 걸립니다. 계속하시겠습니까?",
            "FileSizeLimitExceeded": "파일 크기가 너무 커서 처리하는 데 시간이 더 걸립니다. 계속하시겠습니까?",
            "InvalidUrl": "잘못된 URL",
            "SUM": "일련의 숫자 및/또는 셀을 추가합니다.",
            "SUMIF": "지정된 조건에 따라 셀을 추가합니다.",
            "SUMIFS": "지정된 조건에 따라 셀을 추가합니다.",
            "ABS": "부호가 없는 숫자 값을 반환합니다.",
            "RAND": "0과 1 사이의 난수를 반환합니다.",
            "RANDBETWEEN": "지정된 값을 기반으로 임의의 정수를 반환합니다.",
            "FLOOR": "주어진 요소의 가장 가까운 배수로 숫자를 내림합니다.",
            "CEILING": "주어진 요소의 가장 가까운 배수로 숫자를 반올림합니다.",
            "PRODUCT": "일련의 숫자 및/또는 셀을 곱합니다.",
            "AVERAGE": "일련의 숫자 및/또는 텍스트를 제외한 셀의 평균을 계산합니다.",
            "AVERAGEIF": "지정된 기준에 따라 셀의 평균을 계산합니다.",
            "AVERAGEIFS": "지정된 조건에 따라 셀의 평균을 계산합니다.",
            "AVERAGEA": "TRUE를 1, 텍스트 및 FALSE를 0으로 평가하는 셀의 평균을 계산합니다.",
            "COUNT": "범위의 숫자 값이 포함된 셀의 수를 계산합니다.",
            "COUNTIF": "지정된 조건에 따라 셀 수를 계산합니다.",
            "COUNTIFS": "지정된 조건에 따라 셀 수를 계산합니다.",
            "COUNTA": "범위의 값을 포함하는 셀의 수를 계산합니다.",
            "MIN": "주어진 인수 중 가장 작은 수를 반환합니다.",
            "MAX": "주어진 인수 중 가장 큰 수를 반환합니다.",
            "DATE": "주어진 연도, 월, 일을 기준으로 날짜를 반환합니다.",
            "DAY": "주어진 날짜로부터 일자를 반환합니다.",
            "DAYS": "두 날짜 사이의 일 수를 반환합니다.",
            "IF": "주어진 식에 따라 값을 반환합니다.",
            "IFS": "주어진 여러 표현식을 기반으로 값을 반환합니다.",
            "CalculateAND": "모든 인수가 TRUE이면 TRUE를 반환하고 그렇지 않으면 FALSE를 반환합니다.",
            "CalculateOR": "인수 중 하나라도 TRUE이면 TRUE를 반환하고 그렇지 않으면 FALSE를 반환합니다.",
            "IFERROR": "오류가 발견되지 않으면 값을 반환하고 그렇지 않으면 지정된 값을 반환합니다.",
            "CHOOSE": "색인 번호를 기준으로 값 목록에서 값을 반환합니다.",
            "INDEX": "행 및 열 번호를 기준으로 지정된 범위의 셀 값을 반환합니다.",
            "FIND": "대소문자를 구분하는 다른 문자열 내의 문자열 위치를 반환합니다.",
            "CONCATENATE": "두 개 이상의 문자열을 함께 결합합니다.",
            "CONCAT": "목록 또는 텍스트 문자열 범위를 연결합니다.",
            "SUBTOTAL": "주어진 함수 번호를 사용하여 범위에 대한 소계를 반환합니다.",
            "RADIANS": "각도를 라디안으로 변환합니다.",
            "MATCH": "지정된 범위에서 지정된 값의 상대 위치를 반환합니다.",
            "SLOPE": "데이터 포인트의 선형 회귀에서 선의 기울기를 반환합니다.",
            "INTERCEPT": "선형 회귀를 통해 Y 절편 선의 점을 계산합니다.",
            "UNIQUE": "범위 또는 배열에서 고유한 값을 반환합니다.",
            "TEXT": "값을 지정된 숫자 형식의 텍스트로 변환합니다.",
            "DefineNameExists": "이 이름은 이미 존재합니다. 다른 이름을 입력하십시오.",
            "CircularReference": "수식이 하나 이상의 순환 참조를 참조하는 경우 계산이 잘못될 수 있습니다.",
            "SORT": "배열의 범위를 정렬합니다.",
            "T": "값이 텍스트인지 여부를 확인하고 텍스트를 반환합니다.",
            "EXACT": "두 텍스트 문자열이 정확히 동일한지 확인하고 TRUE 또는 FALSE를 반환합니다.",
            "LEN": "주어진 문자열의 문자 수를 반환합니다.",
            "MOD": "숫자를 제수로 나눈 후 나머지를 반환합니다.",
            "ODD": "양수는 올리고 음수는 가장 가까운 홀수로 내림합니다.",
            "PI": "pi 값을 반환합니다.",
            "COUNTBLANK": "지정된 셀 범위의 빈 셀 수를 반환합니다.",
            "EVEN": "양수는 올리고 음수는 가장 가까운 짝수로 내림합니다.",
            "DECIMAL": "주어진 기수의 텍스트 표현을 10진수로 변환합니다.",
            "ADDRESS": "지정된 행 및 열 번호가 주어지면 셀 참조를 텍스트로 반환합니다.",
            "CHAR": "지정된 숫자의 문자를 반환합니다.",
            "CODE": "주어진 문자열의 첫 번째 문자에 대한 숫자 코드를 반환합니다.",
            "DOLLAR": "숫자를 통화 형식의 텍스트로 변환합니다.",
            "SMALL": "지정된 배열에서 k번째로 작은 값을 반환합니다.",
            "LARGE": "지정된 배열에서 k번째로 큰 값을 반환합니다.",
            "TIME": "시, 분, 초를 시간 형식 텍스트로 변환합니다.",
            "DEGREES": "라디안을 각도로 변환합니다.",
            "FACT": "숫자의 계승을 반환합니다.",
            "MEDIAN": "주어진 숫자 집합의 중앙값을 반환합니다.",
            "EDATE": "지정된 날짜 이전 또는 이후에 주어진 개월 수의 날짜를 반환합니다.",
            "DATEVALUE": "날짜 문자열을 날짜 값으로 변환합니다.",
            "NOW": "현재 날짜와 시간을 반환합니다.",
            "HOUR": "지정된 시간 문자열의 시간을 반환합니다.",
            "MINUTE": "지정된 시간 문자열의 분 수를 반환합니다.",
            "SECOND": "지정된 시간 문자열의 초 수를 반환합니다.",
            "MONTH": "지정된 날짜 문자열의 개월 수를 반환합니다.",
            "OR": "또는",
            "AND": "그리고",
            "CustomFilterDatePlaceHolder": "날짜를 선택하세요",
            "CustomFilterPlaceHolder": "값을 입력하세요",
            "CustomFilter": "맞춤 필터",
            "Between": "사이",
            "MatchCase": "대소문자 일치",
            "DateTimeFilter": "DateTime 필터",
            "Undo": "실행 취소",
            "Redo": "다시 하다",
            "ClearAllFilter": "분명한",
            "ReapplyFilter": "재신청",
            "DateFilter": "날짜 필터",
            "TextFilter": "텍스트 필터",
            "NumberFilter": "숫자 필터",
            "ClearFilter": "필터 지우기",
            "NoResult": "검색 결과가 없습니다",
            "FilterFalse": "거짓",
            "FilterTrue": "진실",
            "Blanks": "공백",
            "SelectAll": "모두 선택",
            "GreaterThanOrEqual": "크거나 같음",
            "GreaterThan": "보다 큰",
            "LessThanOrEqual": "작거나 같음",
            "LessThan": "미만",
            "NotEqual": "같지 않음",
            "Equal": "동일한",
            "Contains": "포함",
            "NotContains": "포함하지 않음",
            "EndsWith": "로 끝나다",
            "NotEndsWith": "다음으로 끝나지 않음",
            "StartsWith": "다음으로 시작",
            "NotStartsWith": "다음으로 시작하지 않음",
            "IsEmpty": "비어 있는",
            "IsNotEmpty": "비어 있지 않음",
            "ClearButton": "분명한",
            "FilterButton": "필터",
            "CancelButton": "취소",
            "OKButton": "확인",
            "Search": "찾다",
            "DataValidation": "데이터 유효성 검사",
            "CellRange": "셀 범위",
            "Allow": "허용하다",
            "Data": "데이터",
            "Minimum": "최저한의",
            "Maximum": "최고",
            "IgnoreBlank": "공백 무시",
            "WholeNumber": "정수",
            "Decimal": "소수",
            "Date": "날짜",
            "TextLength": "텍스트 길이",
            "List": "목록",
            "NotBetween": "사이 아님",
            "EqualTo": "동일",
            "NotEqualTo": "같지 않음",
            "GreaterThanOrEqualTo": "크거나 같음",
            "LessThanOrEqualTo": "작거나 같음",
            "InCellDropDown": "셀 내 드롭다운",
            "Sources": "출처",
            "Value": "값",
            "Retry": "다시 해 보다",
            "DialogError": "목록 소스는 단일 행 또는 열에 대한 참조여야 합니다.",
            "MinMaxError": "최대값은 최소값보다 크거나 같아야 합니다.",
            "Spreadsheet": "스프레드시트",
            "MoreValidation": "이 선택 항목에는 둘 이상의 유효성 검사가 포함되어 있습니다.",
            "FileNameError": "파일 이름은 \\ / : * ? 와 같은 문자를 포함할 수 없습니다. \" < > [ ] |",
            "ValidationError": "이 값은 셀에 대해 정의된 데이터 유효성 검사 제한과 일치하지 않습니다.",
            "ListLengthError": "목록 값은 최대 256자까지만 허용됩니다.",
            "ProtectSheet": "보호 시트",
            "UnprotectSheet": "시트 보호 해제",
            "SelectCells": "잠긴 셀 선택",
            "SelectUnlockedCells": "잠금 해제된 셀 선택",
            "Save": "구하다",
            "EmptyFileName": "파일 이름은 비워둘 수 없습니다.",
            "LargeName": "이름이 너무 깁니다.",
            "FormatCells": "셀 서식 지정",
            "FormatRows": "행 서식 지정",
            "FormatColumns": "형식 열",
            "InsertLinks": "링크 삽입",
            "ProtectContent": "잠긴 셀의 내용 보호",
            "ProtectAllowUser": " 이 워크시트의 모든 사용자에게 다음을 허용합니다.",
            "EditAlert": "변경하려는 셀이 보호되어 있습니다. 변경하려면 시트 보호를 해제하십시오.",
            "ClearValidation": "명확한 유효성 검사",
            "ISNUMBER": "값이 숫자 값으로 구문 분석되면 true를 반환합니다.",
            "ROUND": "숫자를 지정된 자릿수로 반올림합니다.",
            "GEOMEAN": "양의 데이터 배열 또는 범위의 기하 평균을 반환합니다.",
            "POWER": "거듭제곱한 숫자의 결과를 반환합니다.",
            "LOG": "지정한 밑을 기준으로 숫자의 로그를 반환합니다.",
            "TRUNC": "숫자의 잘린 값을 지정된 소수 자릿수로 반환합니다.",
            "EXP": "주어진 숫자만큼 거듭제곱한 e를 반환합니다.",
            "HighlightCellsRules": "셀 하이라이트 규칙",
            "CFEqualTo": "동일",
            "TextThatContains": "다음을 포함하는 텍스트",
            "ADateOccuring": "날짜 발생",
            "DuplicateValues": "중복 값",
            "TopBottomRules": "상위/하위 규칙",
            "Top10Items": "상위 10개 항목",
            "Top10": "상위 10 개",
            "Bottom10Items": "하위 10개 항목",
            "Bottom10": "하위 10위",
            "AboveAverage": "평균 이상",
            "BelowAverage": "평균 이하",
            "FormatCellsGreaterThan": "다음보다 큰 셀 서식 지정:",
            "FormatCellsLessThan": "LESS THAN인 셀 서식 지정:",
            "FormatCellsBetween": "다음 사이에 있는 셀 서식 지정:",
            "FormatCellsEqualTo": "다음과 같은 셀 서식 지정:",
            "FormatCellsThatContainTheText": "텍스트가 포함된 셀 서식 지정:",
            "FormatCellsThatContainADateOccurring": "발생 날짜가 포함된 셀 서식 지정:",
            "FormatCellsDuplicate": "다음을 포함하는 셀 서식 지정:",
            "FormatCellsTop": "TOP 순위에 있는 셀 서식 지정:",
            "FormatCellsBottom": "BOTTOM에서 순위가 ​​매겨지는 셀 서식 지정:",
            "FormatCellsAbove": "평균 이상인 셀 서식 지정:",
            "FormatCellsBelow": "평균 이하인 셀 서식 지정:",
            "With": "~와 함께",
            "DataBars": "데이터 막대",
            "ColorScales": "색상 스케일",
            "IconSets": "아이콘 세트",
            "ClearRules": "명확한 규칙",
            "SelectedCells": "선택한 셀에서 규칙 지우기",
            "EntireSheet": "전체 시트에서 규칙 지우기",
            "LightRedFillWithDarkRedText": "어두운 빨간색 텍스트로 밝은 빨간색 채우기",
            "YellowFillWithDarkYellowText": "진한 노란색 텍스트가 있는 노란색 채우기",
            "GreenFillWithDarkGreenText": "짙은 녹색 텍스트로 녹색 채우기",
            "RedFill": "빨간색 채우기",
            "RedText": "빨간색 텍스트",
            "Duplicate": "복제하다",
            "Unique": "독특한",
            "And": "그리고",
            "WebPage": "웹 페이지",
            "ThisDocument": "이 문서",
            "DisplayText": "디스플레이 텍스트",
            "Url": "URL",
            "CellReference": "셀 참조",
            "DefinedNames": "정의된 이름",
            "EnterTheTextToDisplay": "표시할 텍스트를 입력하세요.",
            "EnterTheUrl": "URL을 입력하세요",
            "INT": "숫자를 가장 가까운 정수로 반환합니다.",
            "SUMPRODUCT": "주어진 배열 범위의 곱의 합계를 반환합니다.",
            "TODAY": "현재 날짜를 날짜 값으로 반환합니다.",
            "ROUNDUP": "0에서 먼 쪽으로 숫자를 반올림합니다.",
            "Link": "링크",
            "Hyperlink": "하이퍼링크",
            "EditHyperlink": "하이퍼링크 편집",
            "OpenHyperlink": "하이퍼링크 열기",
            "RemoveHyperlink": "하이퍼링크 제거",
            "InvalidHyperlinkAlert": "이 사이트의 주소가 유효하지 않습니다. 주소를 확인하고 다시 시도하십시오.",
            "InsertLink": "링크 삽입",
            "EditLink": "링크 편집",
            "WrapText": "줄 바꿈 텍스트",
            "Update": "업데이트",
            "SortAndFilter": "정렬 및 필터",
            "Filter": "필터",
            "FilterCellValue": "선택한 셀의 값으로 필터링",
            "FilterOutOfRangeError": "사용된 범위 내의 셀 또는 범위를 선택하고 다시 시도하십시오.",
            "ClearFilterFrom": "다음에서 필터 지우기",
            "LN": "숫자의 자연 로그를 반환합니다.",
            "DefineNameInValid": "입력한 이름이 유효하지 않습니다.",
            "EmptyError": "값을 입력해야 합니다.",
            "ClearHighlight": "하이라이트 지우기",
            "HighlightInvalidData": "유효하지 않은 데이터 강조",
            "Clear": "분명한",
            "ClearContents": "내용 지우기",
            "ClearAll": "모두 지우기",
            "ClearFormats": "형식 지우기",
            "ClearHyperlinks": "하이퍼링크 지우기",
            "Image": "영상",
            "ConditionalFormatting": "조건부 서식",
            "Directional": "방향",
            "Shapes": "모양",
            "Indicators": "지표",
            "Ratings": "등급",
            "BlueDataBar": "파란색 데이터 막대",
            "GreenDataBar": "녹색 데이터 막대",
            "RedDataBar": "빨간색 데이터 막대",
            "OrangeDataBar": "주황색 데이터 막대",
            "LightBlueDataBar": "하늘색 데이터 막대",
            "PurpleDataBar": "보라색 데이터 막대",
            "GYRColorScale": "녹색 - 노란색 - 빨간색 색 눈금",
            "RYGColorScale": "빨간색 - 노란색 - 녹색 색상 스케일",
            "GWRColorScale": "녹색 - 흰색 - 빨간색 스케일",
            "RWGColorScale": "레드 - 화이트 - 그린 컬러 스케일",
            "BWRColorScale": "파란색 - 흰색 - 빨간색 스케일",
            "RWBColorScale": "빨강 - 흰색 - 파랑 색 눈금",
            "WRColorScale": "화이트 - 레드 컬러 스케일",
            "RWColorScale": "레드 - 화이트 컬러 스케일",
            "GWColorScale": "녹색 - 흰색 색조",
            "WGColorScale": "화이트 - 그린 컬러 스케일",
            "GYColorScale": "녹색 - 노란색 색 눈금",
            "YGColorScale": "노란색 - 녹색 색상 척도",
            "ThreeArrowsColor": "3 화살표 (컬러)",
            "ThreeArrowsGray": "3 화살표 (회색)",
            "ThreeTriangles": "3 삼각형",
            "FourArrowsColor": "4 화살표(회색)",
            "FourArrowsGray": "4 화살표 (컬러)",
            "FiveArrowsColor": "5 화살표(회색)",
            "FiveArrowsGray": "5 화살표(색상)",
            "ThreeTrafficLights1": "신호등 3개(테두리 없음)",
            "ThreeTrafficLights2": "3 신호등(테두리)",
            "ThreeSigns": "3 징후",
            "FourTrafficLights": "4 신호등",
            "RedToBlack": "빨강에서 검정으로",
            "ThreeSymbols1": "3 기호(원)",
            "ThreeSymbols2": "3 기호(동그라미 없음)",
            "ThreeFlags": "3 플래그",
            "ThreeStars": "별 3개",
            "FourRatings": "4 등급",
            "FiveQuarters": "5쿼터",
            "FiveRatings": "5 등급",
            "FiveBoxes": "5박스",
            "Chart": "차트",
            "Column": "열",
            "Bar": "술집",
            "Area": "지역",
            "Pie": "파이",
            "Doughnut": "도넛",
            "PieAndDoughnut": "파이/도넛",
            "Line": "라인",
            "Radar": "레이더",
            "Scatter": "흩어지게하다",
            "ChartDesign": "차트 디자인",
            "ClusteredColumn": "클러스터링된 열",
            "StackedColumn": "누적 열",
            "StackedColumn100": "100% 누적 열",
            "ClusteredBar": "군집 막대",
            "StackedBar": "누적 막대",
            "StackedBar100": "100% 누적 막대",
            "StackedArea": "누적 영역",
            "StackedArea100": "100% 누적 면적",
            "StackedLine": "누적선",
            "StackedLine100": "100% 누적 선",
            "AddChartElement": "차트 요소 추가",
            "Axes": "축",
            "AxisTitle": "축 제목",
            "ChartTitle": "차트 제목",
            "DataLabels": "데이터 레이블",
            "Gridlines": "눈금선",
            "Legends": "전설",
            "PrimaryHorizontal": "기본 수평",
            "PrimaryVertical": "기본 수직",
            "None": "없음",
            "AboveChart": "차트 위",
            "Center": "센터",
            "InsideEnd": "인사이드 엔드",
            "InsideBase": "내부 기지",
            "OutsideEnd": "아웃사이드 엔드",
            "PrimaryMajorHorizontal": "1차 전공 수평",
            "PrimaryMajorVertical": "기본 주요 수직",
            "PrimaryMinorHorizontal": "기본 마이너 수평",
            "PrimaryMinorVertical": "기본 마이너 버티컬",
            "Right": "오른쪽",
            "Left": "왼쪽",
            "Bottom": "맨 아래",
            "Top": "맨 위",
            "SwitchRowColumn": "행/열 전환",
            "ChartTheme": "차트 테마",
            "ChartType": "차트 유형",
            "Material": "재료",
            "Fabric": "구조",
            "Bootstrap": "부트스트랩",
            "HighContrastLight": "고대비 조명",
            "MaterialDark": "소재 다크",
            "FabricDark": "패브릭 다크",
            "HighContrast": "고대비",
            "BootstrapDark": "부트스트랩 다크",
            "Bootstrap4": "부트스트랩4",
            "Bootstrap5Dark": "부트스트랩5 다크",
            "Bootstrap5": "부트스트랩5",
            "Tailwind": "순풍",
            "TailwindDark": "테일윈드 다크",
            "VerticalAxisTitle": "세로축 제목",
            "HorizontalAxisTitle": "가로축 제목",
            "EnterTitle": "제목 입력",
            "UnprotectWorksheet": "시트 보호 해제",
            "ReEnterPassword": "계속하려면 비밀번호를 다시 입력하세요.",
            "SheetPassword": "시트 보호 해제 암호:",
            "ProtectWorkbook": "통합 문서 보호",
            "Password": "비밀번호(선택사항):",
            "EnterThePassword": "비밀번호를 입력하세요",
            "ConfirmPassword": "비밀번호 확인",
            "EnterTheConfirmPassword": "비밀번호를 다시 입력하세요",
            "PasswordAlert": "확인 암호가 동일하지 않습니다",
            "UnprotectWorkbook": "통합 문서 보호 해제",
            "UnprotectPasswordAlert": "제공한 암호가 올바르지 않습니다.",
            "IncorrectPassword": "지정된 비밀번호로 파일 또는 워크시트를 열 수 없습니다.",
            "PasswordAlertMsg": "비밀번호를 입력해주세요",
            "ConfirmPasswordAlertMsg": "확인 비밀번호를 입력해주세요",
            "IsProtected": "보호된다",
            "PDF": "PDF 문서",
            "AutoFillMergeAlertMsg": "이렇게 하려면 병합된 모든 셀의 크기가 같아야 합니다.",
            "Fluent": "유창한",
            "FluentDark": "유창한 어둠",
            "Custom": "관습",
            "WEEKDAY": "날짜에 해당하는 요일을 반환합니다.",
            "FillSeries": "채우기 시리즈",
            "CopyCells": "셀 복사",
            "FillFormattingOnly": "서식만 채우기",
            "FillWithoutFormatting": "서식 없이 채우기",
            "CustomFormat": "사용자 지정 숫자 형식",
            "CustomFormatPlaceholder": "사용자 지정 형식 입력 또는 선택",
            "CustomFormatTypeList": "유형",
            "AddCurrentSelection": "현재 선택 항목을 필터에 추가",
            "Close": "닫다",
            "MoreOptions": "추가 옵션",
            "CellReferenceTypoError": "셀 참조에서 오타를 찾았습니다. 이 참조를 다음과 같이 수정하시겠습니까?"
        },
        "filemanager": {
            "NewFolder": "새 폴더",
            "Upload": "업로드",
            "Delete": "지우다",
            "Rename": "이름 바꾸기",
            "Download": "다운로드",
            "Cut": "절단",
            "Copy": "부",
            "Paste": "풀",
            "SortBy": "정렬 기준",
            "Refresh": "새롭게 하다",
            "Item-Selection": "선택된 항목",
            "Items-Selection": "선택된 항목",
            "View": "전망",
            "Details": "세부",
            "SelectAll": "모두 선택",
            "Open": "열다",
            "Tooltip-NewFolder": "새 폴더",
            "Tooltip-Upload": "업로드",
            "Tooltip-Delete": "지우다",
            "Tooltip-Rename": "이름 바꾸기",
            "Tooltip-Download": "다운로드",
            "Tooltip-Cut": "절단",
            "Tooltip-Copy": "부",
            "Tooltip-Paste": "풀",
            "Tooltip-SortBy": "정렬 기준",
            "Tooltip-Refresh": "새롭게 하다",
            "Tooltip-Selection": "명확한 선택",
            "Tooltip-View": "전망",
            "Tooltip-Details": "세부",
            "Tooltip-SelectAll": "모두 선택",
            "Name": "이름",
            "Size": "크기",
            "DateModified": "수정",
            "DateCreated": "작성일",
            "Path": "통로",
            "Modified": "수정",
            "Created": "만들어진",
            "Location": "위치",
            "Type": "유형",
            "Permission": "허가",
            "Ascending": "오름차순",
            "Descending": "내림차순",
            "None": "없음",
            "View-LargeIcons": "큰 아이콘",
            "View-Details": "세부",
            "Search": "검색",
            "Button-Ok": "확인",
            "Button-Cancel": "취소",
            "Button-Yes": "예",
            "Button-No": "아니",
            "Button-Create": "창조하다",
            "Button-Save": "저장",
            "Header-NewFolder": "폴더",
            "Content-NewFolder": "폴더 이름을 입력하십시오",
            "Header-Rename": "이름 바꾸기",
            "Content-Rename": "새 이름을 입력하십시오",
            "Header-Rename-Confirmation": "확인 이름 변경",
            "Content-Rename-Confirmation": "파일 이름 확장자를 변경하면 파일이 불안정해질 수 있습니다. 변경 하시겠습니까?",
            "Header-Delete": "파일 삭제",
            "Content-Delete": "이 파일을 삭제 하시겠습니까?",
            "Header-Folder-Delete": "폴더 삭제",
            "Content-Folder-Delete": "이 폴더를 삭제 하시겠습니까?",
            "Header-Multiple-Delete": "여러 파일 삭제",
            "Content-Multiple-Delete": "이 {0} 파일을 삭제 하시겠습니까?",
            "Header-Duplicate": "파일 / 폴더가 존재합니다",
            "Content-Duplicate": "{0}이 (가) 이미 존재합니다. 이름을 바꾸고 붙여 넣기 하시겠습니까?",
            "Header-Upload": "파일 업로드하다",
            "Error": "오류",
            "Validation-Empty": "파일 또는 폴더 이름은 비워 둘 수 없습니다.",
            "Validation-Invalid": "파일 또는 폴더 이름 {0}에 유효하지 않은 문자가 있습니다. 다른 이름을 사용하십시오. 유효한 파일 또는 폴더 이름은 점 또는 공백으로 끝날 수 없으며 다음 문자를 포함 할 수 없습니다. \\ / : *? \"<> |",
            "Validation-NewFolder-Exists": "이름이 {0} 인 파일 또는 폴더가 이미 존재합니다.",
            "Validation-Rename-Exists": "{0}의 이름을 {1} (으)로 바꿀 수 없습니다. 대상이 이미 존재합니다.",
            "Folder-Empty": "이 폴더는 비어 있습니다",
            "File-Upload": "파일을 여기로 드래그하여 업로드",
            "Search-Empty": "검색 결과가 없습니다",
            "Search-Key": "다른 키워드로 시도",
            "Filter-Empty": "검색 결과가 없습니다",
            "Filter-Key": "다른 필터로 시도",
            "Sub-Folder-Error": "대상 폴더는 원본 폴더의 하위 폴더입니다.",
            "Same-Folder-Error": "대상 폴더는 원본 폴더와 동일합니다.",
            "Access-Denied": "접근 불가",
            "Access-Details": "이 폴더에 액세스 할 수있는 권한이 없습니다.",
            "Header-Retry": "존재하는 파일입니다",
            "Content-Retry": "이 이름의 파일이 이미이 폴더에 있습니다. 무엇을하고 싶으십니까?",
            "Button-Keep-Both": "둘 다 유지",
            "Button-Replace": "바꾸다",
            "Button-Skip": "건너 뛰기",
            "ApplyAll-Label": "모든 현재 항목에 대해이 작업을 수행하십시오.",
            "KB": "KB",
            "Access-Message": "{0}에 액세스 할 수 없습니다. {1} 조치를 수행하려면 권한이 필요합니다.",
            "Network-Error": "XMLHTTPRequest를 보내지 못했습니다 :로드하지 못했습니다",
            "Server-Error": "서버 오류 : 잘못된 응답"
        },
        "calendar": {
            "today": "오늘"
        },
        "datepicker": {
            "today": "오늘",
            "placeholder": "날짜를 선택하십시오"
        },
        "daterangepicker": {
            "placeholder": "기간을 선택하십시오",
            "startLabel": "시작일",
            "endLabel": "종료일",
            "applyText": "대다",
            "cancelText": "취소",
            "selectedDays": "선택된 요일",
            "days": "일",
            "customRange": "맞춤 범위"
        },
        "timepicker": {
            "placeholder": "시간을 선택하세요"
        },
        "datetimepicker": {
            "today": "오늘",
            "placeholder": "날짜와 시간을 선택하십시오"
        },
        "dialog": {
            "close": "닫기"
        },
        "inplaceeditor": {
            "save": "저장",
            "cancel": "취소",
            "loadingText": "불러오는 중 ...",
            "editIcon": "클릭하여 수정",
            "editAreaClick": "클릭하여 수정",
            "editAreaDoubleClick": "두 번 클릭하여 편집"
        },
        "toast": {
            "close": "닫기"
        },
        "tab": {
            "closeButtonTitle": "닫기"
        },
        "schedule": {
            "day": "일",
            "week": "주",
            "workWeek": "일주일",
            "month": "달",
            "year": "년",
            "agenda": "의제",
            "weekAgenda": "주 일정",
            "workWeekAgenda": "일주일 의제",
            "monthAgenda": "달 의제",
            "today": "오늘",
            "noEvents": "이벤트가 없습니다",
            "emptyContainer": "이 날에는 예정된 행사가 없습니다.",
            "allDay": "하루 종일",
            "start": "스타트",
            "end": "종료",
            "more": "더",
            "close": "닫기",
            "cancel": "취소",
            "noTitle": "(제목 없음)",
            "delete": "지우다",
            "deleteEvent": "이번 행사",
            "deleteMultipleEvent": "여러 이벤트 삭제",
            "selectedItems": "선택된 아이템",
            "deleteSeries": "전체 시리즈",
            "edit": "편집하다",
            "editSeries": "전체 시리즈",
            "editEvent": "이번 행사",
            "createEvent": "창조하다",
            "subject": "제목",
            "addTitle": "제목 추가",
            "moreDetails": "자세한 내용은",
            "save": "저장",
            "editContent": "시리즈의 약속을 어떻게 바꾸시겠습니까?",
            "deleteContent": "이 이벤트를 삭제 하시겠습니까?",
            "deleteMultipleContent": "선택한 이벤트를 삭제 하시겠습니까?",
            "newEvent": "새로운 이벤트",
            "title": "표제",
            "location": "위치",
            "description": "기술",
            "timezone": "시간대",
            "startTimezone": "시간대 시작",
            "endTimezone": "종료 시간대",
            "repeat": "반복",
            "saveButton": "저장",
            "cancelButton": "취소",
            "deleteButton": "지우다",
            "recurrence": "회귀",
            "wrongPattern": "반복 패턴이 유효하지 않습니다.",
            "seriesChangeAlert": "이 시리즈의 특정 인스턴스에 대한 변경 사항을 취소하고 전체 시리즈와 다시 일치 시키려고합니까?",
            "createError": "이벤트 기간은 발생 빈도보다 짧아야합니다. 지속 시간을 줄이거 나 반복 이벤트 편집기에서 반복 패턴을 변경하십시오.",
            "sameDayAlert": "같은 날 두 번의 동일한 이벤트가 발생할 수 없습니다.",
            "occurenceAlert": "동일한 약속의 이후 발생을 건너 뛰는 경우 되풀이 약속의 발생을 다시 예약 할 수 없습니다.",
            "editRecurrence": "반복 편집",
            "repeats": "반복",
            "alert": "경보",
            "startEndError": "선택한 종료 날짜는 시작 날짜 이전에 발생합니다.",
            "invalidDateError": "입력 한 날짜 값이 유효하지 않습니다.",
            "blockAlert": "차단 된 시간 범위 내에서 이벤트를 예약 할 수 없습니다.",
            "ok": "확인",
            "yes": "예",
            "no": "아니",
            "occurrence": "발생",
            "series": "시리즈",
            "previous": "이전",
            "next": "다음",
            "timelineDay": "타임 라인 데이",
            "timelineWeek": "타임 라인 주",
            "timelineWorkWeek": "타임 라인 작업 주간",
            "timelineMonth": "타임 라인 월",
            "timelineYear": "타임 라인",
            "editFollowingEvent": "다음 행사",
            "deleteTitle": "이벤트 삭제",
            "editTitle": "이벤트 수정",
            "beginFrom": "시작",
            "endAt": "종료",
            "expandAllDaySection": "하루 종일 섹션 확장",
            "collapseAllDaySection": "하루 종일 축소 섹션",
            "searchTimezone": "시간대 검색",
            "noRecords": "기록 없음"
        },
        "recurrenceeditor": {
            "none": "없음",
            "daily": "매일",
            "weekly": "주간",
            "monthly": "월간 간행물",
            "month": "달",
            "yearly": "매년",
            "never": "못",
            "until": "까지",
            "count": "카운트",
            "first": "먼저",
            "second": "둘째",
            "third": "제삼",
            "fourth": "네번째",
            "last": "마지막",
            "repeat": "반복",
            "repeatEvery": "매번 반복",
            "on": "반복",
            "end": "종료",
            "onDay": "일",
            "days": "일",
            "weeks": "주",
            "months": "월",
            "years": "연령)",
            "every": "...마다",
            "summaryTimes": "타임스)",
            "summaryOn": "의 위에",
            "summaryUntil": "...까지",
            "summaryRepeat": "반복",
            "summaryDay": "일",
            "summaryWeek": "주",
            "summaryMonth": "월",
            "summaryYear": "연령)",
            "monthWeek": "월 주",
            "monthPosition": "월 위치",
            "monthExpander": "월 확장기",
            "yearExpander": "년 확장기",
            "repeatInterval": "간격 반복"
        },
        "gantt": {
            "emptyRecord": "표시 할 레코드가 없습니다",
            "id": "신분증",
            "name": "이름",
            "startDate": "시작일",
            "endDate": "종료일",
            "duration": "지속",
            "progress": "진행",
            "dependency": "의존",
            "notes": "노트",
            "baselineStartDate": "기준 시작 날짜",
            "baselineEndDate": "기준 종료일",
            "taskMode": "작업 모드",
            "changeScheduleMode": "스케줄 모드 변경",
            "subTasksStartDate": "하위 작업 시작 날짜",
            "subTasksEndDate": "하위 작업 종료 날짜",
            "scheduleStartDate": "스케줄 시작일",
            "scheduleEndDate": "일정 종료일",
            "auto": "자동",
            "manual": "설명서",
            "type": "유형",
            "offset": "오프셋",
            "resourceName": "자원",
            "resourceID": "자원 ID",
            "day": "일",
            "hour": "시",
            "minute": "분",
            "days": "일",
            "hours": "시간",
            "minutes": "의사록",
            "generalTab": "일반",
            "customTab": "맞춤 항목",
            "writeNotes": "메모 작성",
            "addDialogTitle": "새 작업",
            "editDialogTitle": "작업 정보",
            "saveButton": "저장",
            "add": "더하다",
            "edit": "편집하다",
            "update": "최신 정보",
            "delete": "지우다",
            "cancel": "취소",
            "search": "검색",
            "task": " 직무",
            "tasks": " 업무",
            "zoomIn": "확대",
            "zoomOut": "축소",
            "zoomToFit": "맞게 확대",
            "excelExport": "엑셀 내보내기",
            "csvExport": "CSV 내보내기",
            "expandAll": "모두 확장",
            "collapseAll": "모든 축소",
            "nextTimeSpan": "다음 시간",
            "prevTimeSpan": "이전 기간",
            "okText": "확인",
            "confirmDelete": "레코드를 삭제 하시겠습니까?",
            "from": "에서",
            "to": "에",
            "taskLink": "작업 링크",
            "lag": "지연",
            "start": "스타트",
            "finish": "끝",
            "enterValue": "값을 입력하십시오",
            "taskBeforePredecessor_FS": "'{1}'이 (가) 완료되기 전에 시작하기 위해 '{0}'을 (를) 이동했으며 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskAfterPredecessor_FS": "'{1}'에서 '{0}'을 (를) 이동했으며 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskBeforePredecessor_SS": "'{1}'이 (가) 시작되기 전에 시작하도록 '{0}'을 (를) 이동했으며 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskAfterPredecessor_SS": "'{1}'이 (가) 시작된 후 시작하도록 '{0}'을 (를) 이동했으며 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskBeforePredecessor_FF": "'{1}'이 (가) 완료되기 전에 '{0}'을 (를) 이동하여 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskAfterPredecessor_FF": "'{1}'이 (가) 완료된 후 '{0}'을 (를) 이동하고 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskBeforePredecessor_SF": "'{0}'을 (를) '{1}'에서 이동하여 시작했으며 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskAfterPredecessor_SF": "'{1}'이 (가) 시작된 후 '{0}'을 (를) 이동하고 두 작업이 연결되었습니다. 결과적으로 링크를 사용할 수 없습니다. 수행 할 작업을 아래에서 선택하십시오",
            "taskInformation": "작업 정보",
            "deleteTask": "작업 삭제",
            "deleteDependency": "종속성 삭제",
            "convert": "변하게 하다",
            "save": "저장",
            "above": "위",
            "below": "이하",
            "child": "아이",
            "milestone": "획기적 사건",
            "toTask": "할 일",
            "toMilestone": "이정표로",
            "eventMarkers": "이벤트 마커",
            "leftTaskLabel": "왼쪽 작업 라벨",
            "rightTaskLabel": "올바른 작업 라벨",
            "timelineCell": "타임 라인 셀",
            "confirmPredecessorDelete": "종속성 링크를 제거 하시겠습니까?",
            "unit": "단위",
            "work": "작업",
            "taskType": "작업 유형",
            "unassignedTask": "할당되지 않은 작업",
            "group": "그룹",
            "indent": "톱니 모양",
            "outdent": "Outdent",
            "segments": "세그먼트",
            "splitTask": "작업 분할",
            "mergeTask": "작업 병합",
            "left": "왼쪽",
            "right": "권리"
        },
        "richtexteditor": {
            "alignments": "정렬",
            "justifyLeft": "왼쪽 정렬",
            "justifyCenter": "가운데 맞춤",
            "justifyRight": "오른쪽 정렬",
            "justifyFull": "정렬 정렬",
            "fontName": "폰트 이름",
            "fontSize": "폰트 크기",
            "fontColor": "글자 색",
            "backgroundColor": "배경색",
            "bold": "굵게",
            "italic": "이탤릭체",
            "underline": "밑줄",
            "strikethrough": "취소 선",
            "clearFormat": "명확한 형식",
            "clearAll": "모두 지우기",
            "cut": "절단",
            "copy": "부",
            "paste": "풀",
            "unorderedList": "글 머리 기호 목록",
            "orderedList": "번호 매기기 목록",
            "indent": "들여 쓰기",
            "outdent": "들여 쓰기 감소",
            "undo": "실행 취소",
            "redo": "다시 하다",
            "superscript": "어깨 문자",
            "subscript": "아래 첨자",
            "createLink": "하이퍼 링크 삽입",
            "openLink": "링크 열기",
            "editLink": "링크 편집",
            "removeLink": "링크 제거",
            "image": "이미지 삽입",
            "replace": "바꾸다",
            "align": "정렬",
            "caption": "이미지 캡션",
            "remove": "없애다",
            "insertLink": "링크 삽입",
            "display": "디스플레이",
            "altText": "대체 텍스트",
            "dimension": "크기 변경",
            "fullscreen": "최대화",
            "maximize": "최대화",
            "minimize": "최소화",
            "lowerCase": "소문자",
            "upperCase": "대문자",
            "print": "인쇄",
            "formats": "체재",
            "sourcecode": "코드 뷰",
            "preview": "시사",
            "viewside": "뷰 사이드",
            "insertCode": "코드를 입력하세요",
            "linkText": "텍스트 표시",
            "linkTooltipLabel": "표제",
            "linkWebUrl": "웹 주소",
            "linkTitle": "제목을 입력하십시오",
            "linkurl": "http://example.com",
            "linkOpenInNewWindow": "새 창에서 링크 열기",
            "linkHeader": "링크 삽입",
            "dialogInsert": "끼워 넣다",
            "dialogCancel": "취소",
            "dialogUpdate": "최신 정보",
            "imageHeader": "이미지 삽입",
            "imageLinkHeader": "웹에서 링크를 제공 할 수도 있습니다",
            "mdimageLink": "이미지의 URL을 입력하십시오",
            "imageUploadMessage": "여기에 이미지를 드롭하거나 업로드를 찾아보십시오",
            "imageDeviceUploadMessage": "업로드하려면 여기를 클릭하십시오",
            "imageAlternateText": "대체 텍스트",
            "alternateHeader": "대체 텍스트",
            "browse": "검색",
            "imageUrl": "http://example.com/image.png",
            "imageCaption": "표제",
            "imageSizeHeader": "이미지 크기",
            "imageHeight": "신장",
            "imageWidth": "폭",
            "textPlaceholder": "텍스트 입력",
            "inserttablebtn": "테이블 삽입",
            "tabledialogHeader": "테이블 삽입",
            "tableWidth": "폭",
            "cellpadding": "세포 패딩",
            "cellspacing": "셀 간격",
            "columns": "열 수",
            "rows": "행 수",
            "tableRows": "테이블 행",
            "tableColumns": "테이블 열",
            "tableCellHorizontalAlign": "테이블 셀 가로 정렬",
            "tableCellVerticalAlign": "테이블 셀 수직 정렬",
            "createTable": "테이블 만들기",
            "removeTable": "테이블 제거",
            "tableHeader": "테이블 헤더",
            "tableRemove": "테이블 제거",
            "tableCellBackground": "테이블 셀 배경",
            "tableEditProperties": "테이블 편집 속성",
            "styles": "스타일",
            "insertColumnLeft": "왼쪽 열 삽입",
            "insertColumnRight": "열을 오른쪽으로 삽입",
            "deleteColumn": "열 삭제",
            "insertRowBefore": "앞에 행 삽입",
            "insertRowAfter": "뒤에 행 삽입",
            "deleteRow": "행 삭제",
            "tableEditHeader": "테이블 편집",
            "TableHeadingText": "표제",
            "TableColText": "안부",
            "imageInsertLinkHeader": "링크 삽입",
            "editImageHeader": "이미지 편집",
            "alignmentsDropDownLeft": "왼쪽 정렬",
            "alignmentsDropDownCenter": "가운데 맞춤",
            "alignmentsDropDownRight": "오른쪽 정렬",
            "alignmentsDropDownJustify": "정렬 정렬",
            "imageDisplayDropDownInline": "인라인",
            "imageDisplayDropDownBreak": "단절",
            "tableInsertRowDropDownBefore": "앞에 행 삽입",
            "tableInsertRowDropDownAfter": "뒤에 행 삽입",
            "tableInsertRowDropDownDelete": "행 삭제",
            "tableInsertColumnDropDownLeft": "왼쪽 열 삽입",
            "tableInsertColumnDropDownRight": "열을 오른쪽으로 삽입",
            "tableInsertColumnDropDownDelete": "열 삭제",
            "tableVerticalAlignDropDownTop": "상단 정렬",
            "tableVerticalAlignDropDownMiddle": "가운데 정렬",
            "tableVerticalAlignDropDownBottom": "하단 정렬",
            "tableStylesDropDownDashedBorder": "점선 테두리",
            "tableStylesDropDownAlternateRows": "대체 행",
            "pasteFormat": "붙여 넣기 형식",
            "pasteFormatContent": "포맷 작업을 선택하십시오",
            "plainText": "일반 텍스트",
            "cleanFormat": "깨끗한",
            "keepFormat": "유지",
            "pasteDialogOk": "확인",
            "pasteDialogCancel": "취소",
            "fileManager": "파일 관리자",
            "fileDialogHeader": "파일 탐색기",
            "formatsDropDownParagraph": "절",
            "formatsDropDownCode": "암호",
            "formatsDropDownQuotation": "인용",
            "formatsDropDownHeading1": "제목 1",
            "formatsDropDownHeading2": "제목 2",
            "formatsDropDownHeading3": "제목 3",
            "formatsDropDownHeading4": "제목 4",
            "fontNameSegoeUI": "SegoeUI",
            "fontNameArial": "굴림",
            "fontNameGeorgia": "그루지야",
            "fontNameImpact": "타격",
            "fontNameTahoma": "타호 마",
            "fontNameTimesNewRoman": "타임즈 뉴로 만",
            "fontNameVerdana": "Verdana",
            "formatsOLListNumber": "번호",
            "formatsOLListLowerAlpha": "LowerAlpha",
            "formatsOLListUpperAlpha": "UpperAlpha",
            "formatsOLListLowerRoman": "LowerRoman",
            "formatsOLListUpperRoman": "UpperRoman",
            "formatsOLListLowerGreek": "LowerGreek",
            "formatsULListDisc": "디스크",
            "formatsULListCircle": "원",
            "formatsULListSquare": "광장",
            "formatsOLListNone": "없음",
            "formatsULListNone": "없음",
            "formatPainter": "포맷 페인터"
        },
        "colorpicker": {
            "Apply": "대다",
            "Cancel": "취소",
            "ModeSwitcher": "스위치 모드"
        },
        "uploader": {
            "Browse": "검색...",
            "Clear": "명확한",
            "Upload": "업로드",
            "dropFilesHint": "아니면 여기에 파일을 드롭",
            "invalidMaxFileSize": "파일 크기가 너무 큽니다",
            "invalidMinFileSize": "파일 크기가 너무 작습니다",
            "invalidFileType": "파일 형식이 허용되지 않습니다",
            "uploadFailedMessage": "파일을 업로드하지 못했습니다",
            "uploadSuccessMessage": "파일이 성공적으로 업로드되었습니다",
            "removedSuccessMessage": "파일이 성공적으로 제거되었습니다",
            "removedFailedMessage": "파일을 제거 할 수 없습니다",
            "inProgress": "업로드 중",
            "readyToUploadMessage": "업로드 준비 완료",
            "abort": "중단",
            "remove": "없애다",
            "cancel": "취소",
            "delete": "파일 삭제",
            "pauseUpload": "파일 업로드 일시 중지",
            "pause": "중지",
            "resume": "이력서",
            "retry": "다시 해 보다",
            "fileUploadCancel": "파일 업로드가 취소되었습니다"
        },
        "numerictextbox": {
            "incrementTitle": "증가 가치",
            "decrementTitle": "가치 감소"
        },
        "slider": {
            "incrementTitle": "증가하다",
            "decrementTitle": "감소"
        },
        "formValidator": {
            "required": "이 필드는 필수입니다.",
            "email": "유효한 이메일 주소를 입력하세요.",
            "url": "유효한 URL을 입력 해주세요.",
            "date": "유효한 날짜를 입력하십시오.",
            "dateIso": "유효한 날짜를 입력하십시오 (ISO).",
            "creditcard": "유효한 카드 번호를 입력하십시오",
            "number": "유효한 숫자를 입력하십시오.",
            "digits": "숫자 만 입력하십시오.",
            "maxLength": "{0}자를 초과하지 마십시오.",
            "minLength": "{0} 자 이상을 입력하십시오.",
            "rangeLength": "{0}에서 {1} 자 사이의 값을 입력하십시오.",
            "range": "{0}에서 {1} 사이의 값을 입력하십시오.",
            "max": "{0}보다 작거나 같은 값을 입력하십시오.",
            "min": "{0} 이상의 값을 입력하십시오.",
            "regex": "올바른 값을 입력하십시오.",
            "tel": "유효한 전화 번호를 입력하세요.",
            "pattern": "올바른 패턴 값을 입력하십시오.",
            "equalTo": "유효한 일치 텍스트를 입력하십시오"
        },
        "treegrid": {
            "Above": "위",
            "Below": "이하",
            "AddRow": "행 추가",
            "ExpandAll": "모두 확장",
            "CollapseAll": "모든 축소"
        },
        "querybuilder": {
            "StartsWith": "로 시작",
            "EndsWith": "로 끝나다",
            "Contains": "포함",
            "NotLike": "포함",
            "Like": "같지 않은",
            "Equal": "같은",
            "NotEqual": "같지 않음",
            "LessThan": "보다 작음",
            "LessThanOrEqual": "작거나 같음",
            "GreaterThan": "보다 큰",
            "GreaterThanOrEqual": "크거나 같음",
            "Between": "중에서",
            "NotBetween": "사이가 아님",
            "Empty": "빈",
            "NotEmpty": "비우지 않음",
            "In": "에",
            "NotIn": "안으로",
            "NotContains": "포함하지 않음",
            "Remove": "없애다",
            "SelectField": "필드를 선택하십시오",
            "SelectOperator": "운영자 선택",
            "DeleteRule": "이 조건을 제거하십시오",
            "DeleteGroup": "그룹 삭제",
            "AddGroup": "그룹 추가",
            "AddCondition": "조건 추가",
            "Edit": "편집하다",
            "ValidationMessage": "이 필드는 필수입니다",
            "SummaryViewTitle": "요약보기",
            "OtherFields": "다른 분야",
            "AND": "과",
            "OR": "또는",
            "SelectValue": "값을 입력하십시오",
            "IsEmpty": "비었다",
            "IsNotEmpty": "비어 있지 않음",
            "IsNull": "Null",
            "IsNotNull": "Null이 아님",
            "True": "진실",
            "False": "그릇된"
        },
        "barcode": {},
        "datamatrix": {},
        "qrcode": {},
        "pivotview": {
            "grandTotal": "총계",
            "total": "합계",
            "value": "값",
            "noValue": "가치 없음",
            "row": "열",
            "column": "기둥",
            "collapse": "무너짐",
            "expand": "넓히다",
            "rowAxisPrompt": "여기에 행을 놓으십시오",
            "columnAxisPrompt": "여기에 열을 놓으십시오",
            "valueAxisPrompt": "여기에 가치를 떨어 뜨리십시오",
            "filterAxisPrompt": "필터를 여기에 놓으십시오",
            "filter": "필터",
            "filtered": "거르는",
            "sort": "종류",
            "filters": "필터",
            "rows": "행",
            "columns": "열",
            "values": "가치",
            "close": "닫기",
            "cancel": "취소",
            "delete": "지우다",
            "CalculatedField": "계산 된 필드",
            "createCalculatedField": "계산 된 필드 만들기",
            "fieldName": "필드 이름을 입력하십시오",
            "error": "오류",
            "invalidFormula": "잘못된 수식입니다.",
            "dropText": "예 : ( 'Sum (Order_Count)'+ 'Sum (In_Stock)') * 250",
            "dropTextMobile": "여기에서 필드를 추가하고 수식을 편집하십시오.",
            "dropAction": "계산 된 필드는 값 축을 제외한 다른 영역에 배치 할 수 없습니다.",
            "alert": "경보",
            "warning": "경고",
            "ok": "확인",
            "search": "검색",
            "drag": "견인",
            "remove": "없애다",
            "allFields": "모든 분야",
            "formula": "공식",
            "addToRow": "행에 추가",
            "addToColumn": "열에 추가",
            "addToValue": "가치에 추가",
            "addToFilter": "필터에 추가",
            "emptyData": "표시 할 레코드가 없습니다",
            "fieldExist": "이 이름에 필드가 이미 존재합니다. 다른 이름을 입력하십시오.",
            "confirmText": "이 이름에는 이미 계산 필드가 있습니다. 교체 하시겠습니까?",
            "noMatches": "맞지 않는다",
            "format": "다음에 의해 요약 된 값",
            "edit": "편집하다",
            "clear": "명확한",
            "formulaField": "필드를 수식으로 끌어서 놓기",
            "dragField": "필드를 수식으로 드래그",
            "clearFilter": "명확한",
            "by": "으로",
            "all": "모두",
            "multipleItems": "여러 항목",
            "member": "회원",
            "label": "상표",
            "date": "데이트",
            "enterValue": "값을 입력하십시오",
            "chooseDate": "날짜 입력",
            "Before": "전에",
            "BeforeOrEqualTo": "전에 또는 같음",
            "After": "후",
            "AfterOrEqualTo": "후 또는 같음",
            "labelTextContent": "레이블이있는 항목 표시",
            "dateTextContent": "날짜가있는 항목 표시",
            "valueTextContent": "대상 품목 표시",
            "Equals": "같음",
            "DoesNotEquals": "같지 않다",
            "BeginWith": "로 시작",
            "DoesNotBeginWith": "로 시작하지 않는다",
            "EndsWith": "로 끝나다",
            "DoesNotEndsWith": "끝나지 않는다",
            "Contains": "포함",
            "DoesNotContains": "포함되어 있지 않다",
            "GreaterThan": "보다 큰",
            "GreaterThanOrEqualTo": "보다 크거나 같음",
            "LessThan": "보다 작음",
            "LessThanOrEqualTo": "작거나 같음",
            "Between": "중에서",
            "NotBetween": "사이가 아님",
            "And": "과",
            "Sum": "합집합",
            "Count": "카운트",
            "DistinctCount": "고유 카운트",
            "Product": "생성물",
            "Avg": "평균",
            "Min": "최소",
            "SampleVar": "샘플 바",
            "PopulationVar": "인구 Var",
            "RunningTotals": "누적 합계",
            "Max": "맥스",
            "Index": "인덱스",
            "SampleStDev": "샘플 StDev",
            "PopulationStDev": "세인트 데브 인구",
            "PercentageOfRowTotal": "행 총계의 %",
            "PercentageOfParentTotal": "부모 합계의 %",
            "PercentageOfParentColumnTotal": "상위 열 총계의 %",
            "PercentageOfParentRowTotal": "부모 행 총계의 %",
            "DifferenceFrom": "와의 차이점",
            "PercentageOfDifferenceFrom": "차이의 %",
            "PercentageOfGrandTotal": "총계의 %",
            "PercentageOfColumnTotal": "열 합계의 %",
            "NotEquals": "같지 않음",
            "AllValues": "모든 가치",
            "conditionalFormating": "조건부 서식",
            "apply": "대다",
            "condition": "조건 추가",
            "formatLabel": "체재",
            "valueFieldSettings": "값 필드 설정",
            "baseField": "기본 필드 :",
            "baseItem": "기본 아이템 :",
            "summarizeValuesBy": "다음과 같이 값을 요약하십시오.",
            "sourceName": "분야 명 :",
            "sourceCaption": "필드 캡션 :",
            "example": "예 :",
            "editorDataLimitMsg": " 더 많은 아이템. 더 세분화하려면 검색하십시오.",
            "details": "세부",
            "manageRecords": "기록 관리",
            "Years": "연령",
            "Quarters": "병사",
            "Months": "개월",
            "Days": "일",
            "Hours": "시간",
            "Minutes": "의사록",
            "Seconds": "초",
            "save": "보고서 저장",
            "new": "새 보고서 작성",
            "load": "하중",
            "saveAs": "현재 보고서로 저장",
            "rename": "현재 보고서 이름 바꾸기",
            "deleteReport": "현재 보고서 삭제",
            "export": "수출",
            "subTotals": "소계",
            "grandTotals": "총계",
            "reportName": "보고서 이름 :",
            "pdf": "PDF",
            "excel": "뛰어나다",
            "csv": "CSV",
            "png": "PNG",
            "jpeg": "JPEG",
            "svg": "SVG",
            "mdxQuery": "MDX 쿼리",
            "showSubTotals": "소계 표시",
            "doNotShowSubTotals": "소계를 표시하지 않음",
            "showSubTotalsRowsOnly": "부분 합계 행만 표시",
            "showSubTotalsColumnsOnly": "부분 합계 열만 표시",
            "showGrandTotals": "총계 표시",
            "doNotShowGrandTotals": "총계 표시 안함",
            "showGrandTotalsRowsOnly": "총계 행만 표시",
            "showGrandTotalsColumnsOnly": "총계 열만 표시",
            "fieldList": "필드리스트 표시",
            "grid": "테이블 표시",
            "toolbarFormatting": "조건부 서식",
            "chart": "차트",
            "reportMsg": "제공되는 보고서 이름을 입력하십시오 !!!",
            "reportList": "리포트리스트",
            "removeConfirm": "이 보고서를 삭제 하시겠습니까?",
            "emptyReport": "보고서가 없습니다 !!",
            "bar": "바",
            "line": "선",
            "area": "지역",
            "scatter": "흩어지게하다",
            "polar": "극선",
            "of": "의",
            "emptyFormat": "형식을 찾을 수 없습니다 !!!",
            "emptyInput": "값을 입력하십시오",
            "newReportConfirm": "변경 사항을 보고서에 저장 하시겠습니까?",
            "emptyReportName": "보고서 이름을 입력하십시오",
            "qtr": "Qtr",
            "null": "없는",
            "undefined": "찾으시는 주소가 없습니다",
            "groupOutOfRange": "범위를 벗어남",
            "fieldDropErrorAction": "이동중인 필드를 보고서의 해당 영역에 배치 할 수 없습니다",
            "MoreOption": "더...",
            "aggregate": "골재",
            "drillThrough": "드릴 스루",
            "ascending": "오름차순",
            "descending": "내림차순",
            "number": "번호",
            "currency": "통화",
            "percentage": "백분율",
            "formatType": "체재 유형",
            "customText": "통화 기호",
            "symbolPosition": "심볼 위치",
            "left": "왼쪽",
            "right": "권리",
            "grouping": "그룹화",
            "true": "진실",
            "false": "그릇된",
            "decimalPlaces": "소수 자릿수",
            "numberFormat": "숫자 서식",
            "memberType": "필드 타입",
            "formatString": "형식 문자열",
            "expressionField": "표현",
            "customFormat": "맞춤 형식 문자열을 입력하세요",
            "selectedHierarchy": "부모 계층",
            "olapDropText": "예 : [측정]. [주문 수량] + ([측정]. [주문 수량] * 0.10)",
            "Percent": "퍼센트",
            "Custom": "커스텀",
            "Measure": "법안",
            "Dimension": "치수",
            "Standard": "표준",
            "blank": "(공백)",
            "fieldTooltip": "필드를 끌어서 놓아 식을 만듭니다. 그리고 기존 계산 필드를 편집하려면! 그런 다음 '계산 된 멤버'아래의 필드를 선택하면됩니다.",
            "QuarterYear": "분기 년",
            "fieldTitle": "분야 명",
            "drillError": "계산 된 필드의 원시 항목을 표시 할 수 없습니다.",
            "caption": "필드 캡션",
            "copy": "부",
            "defaultReport": "기본 보고서",
            "customFormatString": "맞춤 형식",
            "invalidFormat": "잘못된 형식입니다.",
            "group": "그룹",
            "unGroup": "그룹 해제",
            "invalidSelection": "해당 선택을 그룹화 할 수 없습니다.",
            "groupName": "머리글에 표시 할 캡션을 입력하십시오.",
            "captionName": "그룹 캡션을 입력하십시오",
            "selectedItems": "선택된 아이템",
            "groupFieldCaption": "필드 캡션",
            "groupTitle": "그룹 이름",
            "startAt": "에서 시작",
            "endAt": "에 끝나는",
            "groupBy": "간격",
            "selectGroup": "그룹 선택",
            "numberFormatString": "예 : C, P, 0000 %, ### 0. ## 0 # 등",
            "stackingcolumn": "누적 열",
            "stackingbar": "누적 막대",
            "stackingarea": "누적 영역",
            "stepline": "스텝 라인",
            "steparea": "단계 영역",
            "splinearea": "스플라인 영역",
            "spline": "운형자",
            "stackingcolumn100": "100 % 누적 컬럼",
            "stackingbar100": "100 % 누적 막대",
            "stackingarea100": "100 % 누적 영역",
            "bubble": "거품",
            "pareto": "파레토",
            "radar": "레이더",
            "chartTypeSettings": "차트 유형 설정",
            "multipleAxes": "여러 도끼",
            "sortAscending": "오름차순 정렬",
            "sortDescending": "내림차순 정렬",
            "sortNone": "데이터 순서 정렬",
            "clearCalculatedField": "편집 된 필드 정보 지우기",
            "editCalculatedField": "계산 된 필드 편집",
            "ChartType": "차트 종류",
            "yes": "예",
            "no": "아니",
            "numberFormatMenu": "숫자 서식 ...",
            "conditionalFormatingMenu": "조건부 서식...",
            "removeCalculatedField": "이 계산 된 필드를 삭제 하시겠습니까?",
            "replaceConfirmBefore": "명명 된 보고서",
            "replaceConfirmAfter": " 이미 존재 함. 교체 하시겠습니까?",
            "pie": "파이",
            "funnel": "깔때기",
            "doughnut": "도넛",
            "pyramid": "피라미드",
            "showLegend": "범례 표시",
            "exit": "출구",
            "invalidJSON": "잘못된 JSON 데이터",
            "invalidCSV": "잘못된 CSV 데이터",
            "stacked": "스택",
            "single": "단일",
            "multipleAxisMode": "다축 모드",
            "grandTotalPosition": "총계 위치",
            "top": "맨 위",
            "bottom": "맨 아래",
            "None": "없음",
            "stackingline": "누적 라인",
            "stackingline100": "100 % 누적 라인",
            "rowPage": "행 호출기",
            "rowPerPage": "페이지당 행",
            "columnPage": "열 호출기",
            "columnPerPage": "페이지당 열",
            "goToFirstPage": "첫 페이지로 이동",
            "goToPreviousPage": "이전 페이지로 이동",
            "goToNextPage": "다음 페이지로 이동",
            "goToLastPage": "마지막 페이지로 이동",
            "combined": "결합",
            "subTotalPosition": "소계 위치",
            "auto": "자동"
        },
        "pivotfieldlist": {
            "staticFieldList": "피벗 필드 목록",
            "fieldList": "필드리스트",
            "dropFilterPrompt": "필터를 여기에 놓으십시오",
            "dropColPrompt": "여기에 열을 놓으십시오",
            "dropRowPrompt": "여기에 행을 놓으십시오",
            "dropValPrompt": "여기에 가치를 떨어 뜨리십시오",
            "addPrompt": "여기에 필드 추가",
            "adaptiveFieldHeader": "필드를 선택하십시오",
            "centerHeader": "아래의 축 사이에서 필드를 드래그하십시오.",
            "add": "더하다",
            "drag": "견인",
            "filter": "필터",
            "filtered": "거르는",
            "sort": "종류",
            "remove": "없애다",
            "filters": "필터",
            "rows": "행",
            "columns": "열",
            "values": "가치",
            "CalculatedField": "계산 된 필드",
            "createCalculatedField": "계산 된 필드 만들기",
            "fieldName": "필드 이름을 입력하십시오",
            "error": "오류",
            "invalidFormula": "잘못된 수식입니다.",
            "dropText": "예 : ( 'Sum (Order_Count)'+ 'Sum (In_Stock)' ') * 250",
            "dropTextMobile": "여기에서 필드를 추가하고 수식을 편집하십시오.",
            "dropAction": "계산 된 필드는 값 축을 제외한 다른 영역에 배치 할 수 없습니다.",
            "search": "검색",
            "close": "닫기",
            "cancel": "취소",
            "delete": "지우다",
            "alert": "경보",
            "warning": "경고",
            "ok": "확인",
            "allFields": "모든 분야",
            "formula": "공식",
            "fieldExist": "이 이름에 필드가 이미 존재합니다. 다른 이름을 입력하십시오.",
            "confirmText": "이 이름에는 이미 계산 필드가 있습니다. 교체 하시겠습니까?",
            "noMatches": "맞지 않는다",
            "format": "다음에 의해 요약 된 값",
            "edit": "편집하다",
            "clear": "명확한",
            "formulaField": "필드를 수식으로 끌어서 놓기",
            "dragField": "필드를 수식으로 드래그",
            "clearFilter": "명확한",
            "by": "으로",
            "enterValue": "값을 입력하십시오",
            "chooseDate": "날짜 입력",
            "all": "모두",
            "multipleItems": "여러 항목",
            "Equals": "같음",
            "DoesNotEquals": "같지 않다",
            "BeginWith": "로 시작",
            "DoesNotBeginWith": "로 시작하지 않는다",
            "EndsWith": "로 끝나다",
            "DoesNotEndsWith": "끝나지 않는다",
            "Contains": "포함",
            "DoesNotContains": "포함되어 있지 않다",
            "GreaterThan": "보다 큰",
            "GreaterThanOrEqualTo": "보다 크거나 같음",
            "LessThan": "보다 작음",
            "LessThanOrEqualTo": "작거나 같음",
            "Between": "중에서",
            "NotBetween": "사이가 아님",
            "Before": "전에",
            "BeforeOrEqualTo": "전에 또는 같음",
            "After": "후",
            "AfterOrEqualTo": "후 또는 같음",
            "member": "회원",
            "label": "상표",
            "date": "데이트",
            "value": "값",
            "labelTextContent": "레이블이있는 항목 표시",
            "dateTextContent": "날짜가있는 항목 표시",
            "valueTextContent": "대상 품목 표시",
            "And": "과",
            "Sum": "합집합",
            "Count": "카운트",
            "DistinctCount": "고유 카운트",
            "Product": "생성물",
            "Avg": "평균",
            "Min": "최소",
            "Max": "맥스",
            "Index": "인덱스",
            "SampleStDev": "샘플 StDev",
            "PopulationStDev": "세인트 데브 인구",
            "SampleVar": "샘플 바",
            "PopulationVar": "인구 Var",
            "RunningTotals": "누적 합계",
            "DifferenceFrom": "와의 차이점",
            "PercentageOfDifferenceFrom": "차이의 %",
            "PercentageOfGrandTotal": "총계의 %",
            "PercentageOfColumnTotal": "열 합계의 %",
            "PercentageOfRowTotal": "행 총계의 %",
            "PercentageOfParentTotal": "부모 합계의 %",
            "PercentageOfParentColumnTotal": "상위 열 총계의 %",
            "PercentageOfParentRowTotal": "부모 행 총계의 %",
            "Years": "연령",
            "Quarters": "병사",
            "Months": "개월",
            "Days": "일",
            "Hours": "시간",
            "Minutes": "의사록",
            "Seconds": "초",
            "apply": "대다",
            "valueFieldSettings": "값 필드 설정",
            "sourceName": "분야 명 :",
            "sourceCaption": "필드 캡션 :",
            "summarizeValuesBy": "다음과 같이 값을 요약하십시오.",
            "baseField": "기본 필드 :",
            "baseItem": "기본 아이템 :",
            "example": "예 :",
            "editorDataLimitMsg": " 더 많은 아이템. 더 세분화하려면 검색하십시오.",
            "deferLayoutUpdate": "레이아웃 업데이트 연기",
            "null": "없는",
            "undefined": "찾으시는 주소가 없습니다",
            "groupOutOfRange": "범위를 벗어남",
            "fieldDropErrorAction": "이동중인 필드를 보고서의 해당 영역에 배치 할 수 없습니다",
            "MoreOption": "더...",
            "memberType": "필드 타입",
            "selectedHierarchy": "부모 계층",
            "formatString": "형식 문자열",
            "expressionField": "표현",
            "olapDropText": "예 : [측정]. [주문 수량] + ([측정]. [주문 수량] * 0.10)",
            "customFormat": "맞춤 형식 문자열을 입력하세요",
            "Measure": "법안",
            "Dimension": "치수",
            "Standard": "표준",
            "Currency": "통화",
            "Percent": "퍼센트",
            "Custom": "커스텀",
            "blank": "(공백)",
            "fieldTooltip": "필드를 끌어서 놓아 식을 만듭니다. 그리고 기존 계산 필드를 편집하려면! '계산 된 멤버'아래의 필드를 선택하면됩니다.",
            "fieldTitle": "분야 명",
            "QuarterYear": "분기 년",
            "caption": "필드 캡션",
            "copy": "부",
            "group": "그룹",
            "numberFormatString": "예 : C, P, 0000 %, ### 0. ## 0 # 등",
            "sortAscending": "오름차순 정렬",
            "sortDescending": "내림차순 정렬",
            "sortNone": "데이터 순서 정렬",
            "clearCalculatedField": "편집 된 필드 정보 지우기",
            "editCalculatedField": "계산 된 필드 편집",
            "selectGroup": "그룹 선택",
            "of": "의",
            "removeCalculatedField": "이 계산 된 필드를 삭제 하시겠습니까?",
            "yes": "이 계산된 필드를 삭제하시겠습니까?",
            "no": "예",
            "None": "아니요"
        },
        "pdfviewer": {
            "PdfViewer": "PDF 뷰어",
            "Cancel": "취소",
            "Download file": "파일 다운로드",
            "Download": "다운로드",
            "Enter Password": "이 문서는 암호로 보호되어 있습니다. 비밀번호를 입력하십시오.",
            "File Corrupted": "손상된 파일",
            "File Corrupted Content": "파일이 손상되어 열 수 없습니다.",
            "Fit Page": "페이지 맞추기",
            "Fit Width": "너비에 맞게",
            "Automatic": "자동적 인",
            "Go To First Page": "첫 페이지 표시",
            "Invalid Password": "잘못된 비밀번호. 다시 시도하십시오.",
            "Next Page": "다음 페이지 표시",
            "OK": "확인",
            "Open": "파일 열기",
            "Page Number": "현재 페이지 번호",
            "Previous Page": "이전 페이지 표시",
            "Go To Last Page": "마지막 페이지 표시",
            "Zoom": "줌",
            "Zoom In": "확대",
            "Zoom Out": "축소",
            "Page Thumbnails": "페이지 축소판",
            "Bookmarks": "북마크",
            "Print": "파일 인쇄",
            "Password Protected": "암호 필요",
            "Copy": "부",
            "Text Selection": "텍스트 선택 도구",
            "Panning": "팬 모드",
            "Text Search": "텍스트 찾기",
            "Find in document": "문서에서 찾기",
            "Match case": "경기 사례",
            "Apply": "대다",
            "GoToPage": "페이지로 이동",
            "No matches": "뷰어가 문서 검색을 완료했습니다. 더 이상 일치하는 항목이 없습니다",
            "No Text Found": "텍스트를 찾을 수 없습니다",
            "Undo": "실행 취소",
            "Redo": "다시 하다",
            "Annotation": "주석 추가 또는 편집",
            "Highlight": "텍스트 강조",
            "Underline": "밑줄 텍스트",
            "Strikethrough": "취소 선 텍스트",
            "Delete": "주석 삭제",
            "Opacity": "불투명",
            "Color edit": "색 변경",
            "Opacity edit": "불투명도 변경",
            "highlight": "가장 밝은 부분",
            "underline": "밑줄",
            "strikethrough": "스트라이크 스루",
            "Server error": "웹 서비스가 듣고 있지 않습니다. PDF 뷰어는 모든 기능을 웹 서비스에 의존합니다. 계속하려면 웹 서비스를 시작하십시오.",
            "Open text": "열다",
            "First text": "첫 페이지",
            "Previous text": "이전 페이지",
            "Next text": "다음 페이지",
            "Last text": "마지막 페이지",
            "Zoom in text": "확대",
            "Zoom out text": "축소",
            "Selection text": "선택",
            "Pan text": "팬",
            "Print text": "인쇄",
            "Seach text": "검색",
            "Annotation Edit text": "주석 편집",
            "FormDesigner": "양식 필드 추가 및 편집",
            "Highlight context": "가장 밝은 부분",
            "Underline context": "밑줄",
            "Strikethrough context": "파업",
            "Client error": "클라이언트 측 오류가 발견되었습니다. AjaxRequestSettings 속성에서 제공되는 커스텀 헤더와 ServerActionSettings 속성에서 웹 액션 메소드를 확인하세요.",
            "Search text": "검색",
            "FormDesigner Edit text": "양식 필드 추가 및 편집",
            "Line Thickness": "선 두께",
            "Line Properties": "선 속성",
            "Start Arrow": "시작 화살표",
            "End Arrow": "끝 화살표",
            "Line Style": "선 스타일",
            "Fill Color": "채우기 색상",
            "Line Color": "선 색",
            "None": "없음",
            "Open Arrow": "열다",
            "Closed Arrow": "닫은",
            "Round Arrow": "일주",
            "Square Arrow": "광장",
            "Diamond Arrow": "다이아몬드",
            "Butt": "대상",
            "Cut": "절단",
            "Paste": "풀",
            "Delete Context": "지우다",
            "Properties": "속성",
            "Add Stamp": "스탬프 추가",
            "Add Shapes": "도형 추가",
            "Stroke edit": "획 색상 변경",
            "Change thickness": "테두리 두께 변경",
            "Add line": "라인 추가",
            "Add arrow": "화살표 추가",
            "Add rectangle": "사각형 추가",
            "Add circle": "서클 추가",
            "Add polygon": "다각형 추가",
            "Add Comments": "의견 추가",
            "Comments": "코멘트",
            "SubmitForm": "양식 제출",
            "No Comments Yet": "아직 코멘트가 없습니다",
            "Accepted": "받아 들여",
            "Completed": "완료",
            "Cancelled": "취소 된",
            "Rejected": "거부",
            "Leader Length": "리더 길이",
            "Scale Ratio": "스케일 비율",
            "Calibrate": "교정",
            "Calibrate Distance": "거리 보정",
            "Calibrate Perimeter": "주변 보정",
            "Calibrate Area": "면적 교정",
            "Calibrate Radius": "반경 보정",
            "Calibrate Volume": "볼륨 보정",
            "Depth": "깊이",
            "Closed": "닫은",
            "Round": "일주",
            "Square": "광장",
            "Diamond": "다이아몬드",
            "Edit": "편집하다",
            "Comment": "논평",
            "Comment Panel": "댓글 패널",
            "Set Status": "상태 설정",
            "Post": "게시하다",
            "Page": "페이지",
            "Add a comment": "코멘트를 추가하다",
            "Add a reply": "답글 추가",
            "Import Annotations": "JSON 파일에서 주석 가져 오기",
            "Export Annotations": "주석을 JSON 파일로 내보내기",
            "Export XFDF": "XFDF 파일로 주석 내보내기",
            "Import XFDF": "XFDF 파일에서 주석 가져 오기",
            "Add": "더하다",
            "Clear": "맑은",
            "Bold": "굵게",
            "Italic": "이탤릭체",
            "Strikethroughs": "취소 선",
            "Underlines": "밑줄",
            "Superscript": "위에 쓴",
            "Subscript": "아래 첨자",
            "Align left": "왼쪽 정렬",
            "Align right": "오른쪽 정렬",
            "Center": "센터",
            "Justify": "신이 옳다고 하다",
            "Font color": "글자 색",
            "Text Align": "텍스트 정렬",
            "Text Properties": "글꼴 스타일",
            "SignatureFieldDialogHeaderText": "서명 추가",
            "HandwrittenSignatureDialogHeaderText": "서명 추가",
            "InitialFieldDialogHeaderText": "이니셜 추가",
            "HandwrittenInitialDialogHeaderText": "이니셜 추가",
            "Draw Ink": "잉크 그리기",
            "Create": "창조하다",
            "Font family": "글꼴 군",
            "Font size": "글꼴 크기",
            "Free Text": "무료 문자",
            "Import Failed": "잘못된 JSON 파일 유형 또는 파일 이름입니다. 유효한 JSON 파일을 선택하십시오",
            "File not found": "가져온 JSON 파일이 원하는 위치에 없습니다.",
            "Export Failed": "주석 내보내기 작업이 실패했습니다. 주석이 제대로 추가되었는지 확인하십시오.",
            "of": "의 ",
            "Dynamic": "동적",
            "Standard Business": "표준 비즈니스",
            "Sign Here": "여기에 서명",
            "Custom Stamp": "맞춤 스탬프",
            "Enter Signature as Name": "당신의 이름을 입력",
            "Draw-hand Signature": "무승부",
            "Type Signature": "유형",
            "Upload Signature": "업로드",
            "Browse Signature Image": "검색",
            "Save Signature": "서명 저장",
            "Save Initial": "이니셜 저장",
            "Textbox": "텍스트 상자",
            "Password": "비밀번호",
            "Check Box": "체크박스",
            "Radio Button": "라디오 버튼",
            "Dropdown": "쓰러지 다",
            "List Box": "리스트 박스",
            "Signature": "서명",
            "Delete FormField": "양식 필드 삭제",
            "Textbox Properties": "텍스트 상자 속성",
            "Name": "이름",
            "Tooltip": "툴팁",
            "Value": "값",
            "Form Field Visibility": "양식 필드 가시성",
            "Read Only": "읽기 전용",
            "Required": "필수의",
            "Checked": "확인됨",
            "Show Printing": "인쇄 표시",
            "Formatting": "체재",
            "Fill": "채우다",
            "Border": "국경",
            "Border Color": "테두리 색상",
            "Thickness": "두께",
            "Max Length": "최대 길이",
            "List Item": "상품명",
            "Export Value": "항목 값",
            "Dropdown Item List": "드롭다운 항목 목록",
            "List Box Item List": "목록 상자 항목 목록",
            "General": "일반",
            "Appearance": "모습",
            "Options": "옵션",
            "Delete Item": "삭제",
            "Up": "위로",
            "Down": "아래에",
            "Multiline": "여러 줄",
            "Revised": "개정",
            "Reviewed": "검토됨",
            "Received": "받았다",
            "Confidential": "비밀의",
            "Approved": "Approved",
            "Not Approved": "승인이 거절 됨",
            "Witness": "증인",
            "Initial Here": "여기에 이니셜",
            "Draft": "초안",
            "Final": "결정적인",
            "For Public Release": "공개용",
            "Not For Public Release": "공개용 아님",
            "For Comment": "코멘트를 위해",
            "Void": "무효의",
            "Preliminary Results": "예비 결과",
            "Information Only": "정보용",
            "in": "안에",
            "m": "중",
            "ft_in": "피트_안에",
            "ft": "피트",
            "p": "피",
            "cm": "센티미터",
            "mm": "mm",
            "pt": "태평양 표준시",
            "cu": "큐",
            "sq": "평방",
            "Initial": "초기의"
        },
        "diagram": {
            "Copy": "부",
            "Cut": "절단",
            "Paste": "풀",
            "Undo": "실행 취소",
            "Redo": "다시 하다",
            "SelectAll": "모두 선택",
            "Grouping": "그룹화",
            "Group": "그룹",
            "UnGroup": "그룹 해제",
            "Order": "주문",
            "BringToFront": "앞으로 가져 오기",
            "MoveForward": "앞으로가",
            "SendToBack": "다시 보내기",
            "SendBackward": "뒤로 보내기"
        },
        "inplace-editor": {
            "editIcon": "클릭하여 수정",
            "save": "저장",
            "cancel": "취소"
        },
        "DocumentEditor": {
            "Table": "표",
            "Row": "열",
            "Cell": "세포",
            "Ok": "확인",
            "Cancel": "취소",
            "Size": "크기",
            "Preferred Width": "선호하는 폭",
            "Points": "포인트들",
            "Percent": "퍼센트",
            "Measure in": "측정",
            "Alignment": "조정",
            "Left": "왼쪽",
            "Center": "센터",
            "Right": "권리",
            "Justify": "신이 옳다고 하다",
            "Indent from left": "왼쪽에서 들여 쓰기",
            "Borders and Shading": "테두리와 음영",
            "Options": "옵션",
            "Specify height": "높이 지정",
            "At least": "적어도",
            "Exactly": "바로 그거죠",
            "Row height is": "행 높이",
            "Allow row to break across pages": "행이 여러 페이지에서 나뉘도록 허용",
            "Repeat as header row at the top of each page": "각 페이지 상단에 헤더 행으로 반복",
            "Vertical alignment": "수직 정렬",
            "Top": "상단",
            "Bottom": "바닥",
            "Default cell margins": "기본 셀 여백",
            "Default cell spacing": "기본 셀 간격",
            "Allow spacing between cells": "셀 간 간격 허용",
            "Cell margins": "셀 마진",
            "Same as the whole table": "전체 테이블과 동일",
            "Borders": "테두리",
            "None": "없음",
            "Single": "하나의",
            "Dot": "점",
            "DashSmallGap": "DashSmallGap",
            "DashLargeGap": "DashLargeGap",
            "DashDot": "대시닷",
            "DashDotDot": "대시닷닷",
            "Double": "더블",
            "Triple": "삼루타",
            "ThinThickSmallGap": "ThinThickSmallGap",
            "ThickThinSmallGap": "두껍게얇게작은갭",
            "ThinThickThinSmallGap": "가늘게 굵게 가늘게 작은 갭",
            "ThinThickMediumGap": "ThinThickMediumGap",
            "ThickThinMediumGap": "두껍게얇게중간갭",
            "ThinThickThinMediumGap": "ThinThickThinMediumGap",
            "ThinThickLargeGap": "ThinThickLargeGap",
            "ThickThinLargeGap": "두껍게얇게큰갭",
            "ThinThickThinLargeGap": "가늘게 굵게 가늘게 큰 갭",
            "SingleWavy": "싱글웨이비",
            "DoubleWavy": "더블웨이비",
            "DashDotStroked": "대시닷스트로크",
            "Emboss3D": "엠보스3D",
            "Engrave3D": "새기다3D",
            "Outset": "시초",
            "Inset": "삽입",
            "Thick": "두꺼운",
            "Style": "스타일",
            "Width": "폭",
            "Height": "신장",
            "Letter": "편지",
            "Tabloid": "정제",
            "Legal": "적법한",
            "Statement": "성명서",
            "Executive": "행정부",
            "A3": "A3",
            "A4": "A4",
            "A5": "A5",
            "B4": "B4",
            "B5": "B5",
            "Custom Size": "사용자 정의 크기",
            "Different odd and even": "다른 홀수 및 짝수",
            "Different first page": "다른 첫 페이지",
            "From edge": "가장자리에서",
            "Header": "머리글",
            "Footer": "보행인",
            "Margin": "여백",
            "Paper": "종이",
            "Layout": "나열한 것",
            "Orientation": "정위",
            "Landscape": "경치",
            "Portrait": "초상화",
            "Table Of Contents": "목차",
            "Show page numbers": "페이지 번호 표시",
            "Right align page numbers": "페이지 번호를 오른쪽 정렬",
            "Nothing": "아무것도",
            "Tab leader": "탭 리더",
            "Show levels": "레벨 표시",
            "Use hyperlinks instead of page numbers": "페이지 번호 대신 하이퍼 링크 사용",
            "Build table of contents from": "목차 구축",
            "Styles": "스타일",
            "Available styles": "사용 가능한 스타일",
            "TOC level": "TOC 레벨",
            "Heading": "표제",
            "List Paragraph": "단락 목록",
            "Normal": "정상",
            "Outline levels": "개요 수준",
            "Table entry fields": "테이블 입력 필드",
            "Modify": "수정",
            "Color": "색깔",
            "Setting": "환경",
            "Box": "상자",
            "All": "모두",
            "Custom": "커스텀",
            "Preview": "시사",
            "Shading": "농담",
            "Fill": "가득 따르다",
            "Apply To": "적용하다",
            "Table Properties": "테이블 속성",
            "Cell Options": "셀 옵션",
            "Table Options": "테이블 옵션",
            "Insert Table": "테이블 삽입",
            "Number of columns": "열 수",
            "Number of rows": "행 수",
            "Text to display": "표시 할 텍스트",
            "Address": "주소",
            "Insert Hyperlink": "하이퍼 링크 삽입",
            "Edit Hyperlink": "하이퍼 링크 편집",
            "Insert": "끼워 넣다",
            "General": "일반",
            "Indentation": "들여 쓰기",
            "Before text": "텍스트 전에",
            "Special": "특별한",
            "First line": "첫 번째 줄",
            "Hanging": "교수형",
            "After text": "텍스트 후",
            "By": "으로",
            "Before": "전에",
            "Line Spacing": "줄 간격",
            "After": "후",
            "At": "에서",
            "Multiple": "배수",
            "Spacing": "간격",
            "Define new Multilevel list": "새로운 다단계 목록 정의",
            "List level": "리스트 레벨",
            "Choose level to modify": "수정할 레벨을 선택하십시오",
            "Level": "수평",
            "Number format": "숫자 형식",
            "Number style for this level": "이 레벨의 숫자 스타일",
            "Enter formatting for number": "숫자 형식을 입력하십시오",
            "Start at": "에서 시작",
            "Restart list after": "다음에 다시 시작 목록",
            "Position": "위치",
            "Text indent at": "텍스트 들여 쓰기",
            "Aligned at": "에 정렬",
            "Follow number with": "와 번호를 따르십시오",
            "Tab character": "탭 문자",
            "Space": "우주",
            "Arabic": "아라비아 말",
            "UpRoman": "로마",
            "LowRoman": "로로 만",
            "UpLetter": "편지",
            "LowLetter": "낮은 글자",
            "Number": "번호",
            "Leading zero": "선행 제로",
            "Bullet": "총알",
            "Ordinal": "서수",
            "Ordinal Text": "서수 텍스트",
            "For East": "동쪽",
            "No Restart": "다시 시작하지 않음",
            "Font": "폰트",
            "Font style": "폰트 스타일",
            "Underline style": "밑줄 스타일",
            "Font color": "글자 색",
            "Effects": "효과",
            "Strikethrough": "취소 선",
            "Superscript": "어깨 문자",
            "Subscript": "아래 첨자",
            "Double strikethrough": "이중 취소 선",
            "Regular": "정규병",
            "Bold": "굵게",
            "Italic": "이탤릭체",
            "Cut": "절단",
            "Copy": "부",
            "Paste": "풀",
            "Hyperlink": "하이퍼 링크",
            "Open Hyperlink": "하이퍼 링크 열기",
            "Copy Hyperlink": "하이퍼 링크 복사",
            "Remove Hyperlink": "하이퍼 링크 제거",
            "Paragraph": "절",
            "Merge Cells": "셀 병합",
            "Insert Above": "위에 삽입",
            "Insert Below": "아래에 삽입",
            "Insert Left": "왼쪽 삽입",
            "Insert Right": "오른쪽 삽입",
            "Delete": "지우다",
            "Delete Table": "테이블 삭제",
            "Delete Row": "행 삭제",
            "Delete Column": "열 삭제",
            "File Name": "파일 이름",
            "Format Type": "체재 유형",
            "Save": "저장",
            "Navigation": "항해",
            "Results": "결과",
            "Replace": "바꾸다",
            "Replace All": "전부 교체",
            "We replaced all": "우리는 모두 교체",
            "Find": "찾기",
            "No matches": "맞지 않는다",
            "All Done": "모두 완료",
            "Result": "결과",
            "of": "의",
            "instances": "인스턴스",
            "with": "와",
            "Click to follow link": "링크를 클릭하십시오",
            "Continue Numbering": "번호 매기기 계속",
            "Bookmark name": "북마크 이름",
            "Close": "닫기",
            "Restart At": "다시 시작",
            "Properties": "속성",
            "Name": "이름",
            "Style type": "스타일 타입",
            "Style based on": "기반 스타일",
            "Style for following paragraph": "다음 단락 스타일",
            "Formatting": "서식",
            "Numbering and Bullets": "번호 매기기 및 글 머리 기호",
            "Numbering": "넘버링",
            "Update Field": "업데이트 필드",
            "Edit Field": "필드 편집",
            "Bookmark": "서표",
            "Page Setup": "페이지 설정",
            "No bookmarks found": "북마크를 찾을 수 없습니다",
            "Number format tooltip information": "단일 레벨 숫자 형식 : </br> [PREFIX] % [LEVELNUMBER] [SUFFIX] </br> 예 : 장 % 1. 다음과 같은 번호가 표시됩니다. 1 장. 항목 </br> 2 장. 항목 </br> ... </br> 장 N. 항목 </br> </br> </br> 다단계 번호 형식 : </ br > [PREFIX] % [LEVELNUMBER] [SUFFIX] + [PREFIX] % [LEVELNUMBER] [SUFFIX] </br> 예 : % 1. % 2. 1.1과 같은 번호가 표시됩니다. 항목 </br> 1.2. 항목 </br>… </br> 1.N. 안건",
            "Format": "체재",
            "Create New Style": "새로운 스타일 만들기",
            "Modify Style": "스타일 수정",
            "New": "새로운",
            "Bullets": "글 머리 기호",
            "Use bookmarks": "북마크 사용",
            "Table of Contents": "목차"
        },
        "drawing": {},
        "rich-text-editor": {
            "alignments": "정렬",
            "justifyleft": "왼쪽",
            "justifycenter": "justifyCenter",
            "justifyright": "정당화",
            "justifyfull": "정당화",
            "fontname": "fontName",
            "fontsize": "fontSize",
            "fontcolor": "글자 색",
            "backgroundcolor": "배경색",
            "clearformat": "clearFormat",
            "clearall": "모두 지우기",
            "unorderedlist": "정렬되지 않은 목록",
            "orderedlist": "orderedList",
            "createlink": "createLink",
            "openlink": "openLink",
            "editlink": "editLink",
            "removelink": "링크 제거",
            "openimagelink": "openLink",
            "editimagelink": "editLink",
            "removeimagelink": "링크 제거",
            "image": "영상",
            "replace": "바꾸다",
            "align": "정렬",
            "caption": "표제",
            "remove": "없애다",
            "insertlink": "insertLink",
            "display": "디스플레이",
            "alttext": "대체 텍스트",
            "dimension": "치수",
            "fullscreen": "전체 화면",
            "maximize": "최대화하다",
            "minimize": "최소화",
            "lowercase": "소문자",
            "uppercase": "대문자",
            "print": "인쇄",
            "formats": "체재",
            "sourcecode": "소스 코드",
            "preview": "시사",
            "viewside": "전망",
            "insertcode": "코드를 입력하세요",
            "justifyLeft": "왼쪽 정렬",
            "justifyCenter": "가운데 맞춤",
            "justifyRight": "오른쪽 정렬",
            "justifyFull": "정렬 정렬",
            "fontName": "폰트 이름",
            "fontSize": "폰트 크기",
            "fontColor": "글자 색",
            "backgroundColor": "배경색",
            "bold": "굵게",
            "italic": "이탤릭체",
            "underline": "밑줄",
            "strikethrough": "취소 선",
            "cut": "절단",
            "copy": "부",
            "paste": "풀",
            "indent": "톱니 모양",
            "outdent": "외치다",
            "undo": "실행 취소",
            "redo": "다시 하다",
            "superscript": "어깨 문자",
            "subscript": "아래 첨자",
            "createLink": "하이퍼 링크 삽입",
            "openLink": "링크 열기",
            "editLink": "링크 편집",
            "removeLink": "링크 제거",
            "insertLink": "링크 삽입",
            "altText": "대체 텍스트",
            "lowerCase": "소문자",
            "upperCase": "대문자",
            "insertCode": "코드를 입력하세요",
            "linkText": "텍스트 표시",
            "linkTooltipLabel": "툴팁",
            "linkWebUrl": "웹 주소",
            "linkurl": "http://example.com",
            "linkOpenInNewWindow": "새 창에서 링크 열기",
            "linkHeader": "링크 삽입",
            "dialogInsert": "끼워 넣다",
            "dialogCancel": "취소",
            "dialogUpdate": "최신 정보",
            "imageHeader": "이미지 삽입",
            "imageLinkHeader": "웹에서 링크를 제공 할 수도 있습니다",
            "mdimageLink": "이미지의 URL을 입력하십시오",
            "imageUploadMessage": "여기에 이미지를 드롭하거나 업로드를 찾아보십시오",
            "imageDeviceUploadMessage": "업로드하려면 여기를 클릭하십시오",
            "imageAlternateText": "대체 텍스트",
            "alternateHeader": "대체 텍스트",
            "browse": "검색",
            "imageUrl": "http://example.com/image.png",
            "imageCaption": "표제",
            "imageSizeHeader": "이미지 크기",
            "imageHeight": "신장",
            "imageWidth": "폭",
            "textPlaceholder": "텍스트 입력",
            "tblhead": "1X1",
            "inserttablebtn": "테이블 삽입",
            "tabledialogHeader": "테이블 삽입",
            "tableWidth": "너비",
            "cellpadding": "셀 패딩",
            "cellspacing": "셀 간격",
            "columns": "열 수",
            "rows": "행 수",
            "tableRows": "테이블 행",
            "tableColumns": "테이블 열",
            "tableCellHorizontalAlign": "표 셀 가로 맞춤",
            "tableCellVerticalAlign": "표 셀 세로 맞춤",
            "createTable": "테이블 생성",
            "removeTable": "테이블 제거",
            "tableHeader": "테이블 헤더",
            "tableRemove": "테이블 제거",
            "tableCellBackground": "테이블 셀 배경",
            "tableEditProperties": "테이블 편집 속성",
            "styles": "스타일",
            "insertColumnLeft": "왼쪽 열 삽입",
            "insertColumnRight": "열 오른쪽 삽입",
            "deleteColumn": "열 삭제",
            "insertRowBefore": "앞에 행 삽입",
            "insertRowAfter": "다음에 행 삽입",
            "deleteRow": "행 삭제",
            "tablerows": "테이블 행",
            "tablecolumns": "테이블 열",
            "tablecellhorizontalalign": "tableCellHorizontalAlign",
            "tablecellverticalalign": "tableCellVerticalAlign",
            "createtable": "테이블 생성",
            "removetable": "테이블 제거",
            "tableheader": "테이블 헤더",
            "tableremove": "테이블제거",
            "tablecellbackground": "테이블 셀 배경",
            "tableeditproperties": "테이블편집속성",
            "insertcolumnleft": "insertColumnLeft",
            "insertcolumnright": "insertColumnRight",
            "deletecolumn": "삭제 열",
            "aligntop": "상단 정렬",
            "alignmiddle": "중간 정렬",
            "alignbottom": "하단 정렬",
            "insertrowbefore": "insertRowBefore",
            "insertrowafter": "삽입 행 후",
            "deleterow": "행 삭제"
        },
        "drop-down-base": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패"
        },
        "chart": {
            "Zoom": "줌",
            "ZoomIn": "확대",
            "ZoomOut": "축소",
            "Reset": "초기화",
            "Pan": "팬",
            "ResetZoom": "줌 재설정"
        },
        "maps": {
            "Zoom": "줌",
            "ZoomIn": "확대",
            "ZoomOut": "축소",
            "Reset": "초기화",
            "Pan": "팬",
            "ResetZoom": "줌 재설정",
            "ImageNotFound": "이미지를 찾을 수 없음"
        },
        "drop-down-tree": {
            "noRecordsTemplate": "기록 없음",
            "actionFailureTemplate": "요청 실패",
            "overflowCountTemplate": "+ $ {count} 개 더 ..",
            "totalCountTemplate": "$ {count} 선택됨"
        },
        "PdfViewer": {
            "PdfViewer": "PDF 뷰어",
            "Cancel": "취소",
            "Download file": "파일 다운로드",
            "Download": "다운로드",
            "Enter Password": "이 문서는 암호로 보호되어 있습니다. 비밀번호를 입력하십시오.",
            "File Corrupted": "손상된 파일",
            "File Corrupted Content": "파일이 손상되어 열 수 없습니다.",
            "Fit Page": "페이지 맞추기",
            "Fit Width": "너비에 맞게",
            "Automatic": "자동적 인",
            "Go To First Page": "첫 페이지 표시",
            "Invalid Password": "잘못된 비밀번호. 다시 시도하십시오.",
            "Next Page": "다음 페이지 표시",
            "OK": "확인",
            "Open": "파일 열기",
            "Page Number": "현재 페이지 번호",
            "Previous Page": "이전 페이지 표시",
            "Go To Last Page": "마지막 페이지 표시",
            "Zoom": "줌",
            "Zoom In": "확대",
            "Zoom Out": "축소",
            "Page Thumbnails": "페이지 축소판",
            "Bookmarks": "북마크",
            "Print": "파일 인쇄",
            "Password Protected": "암호 필요",
            "Copy": "부",
            "Text Selection": "텍스트 선택 도구",
            "Panning": "팬 모드",
            "Text Search": "텍스트 찾기",
            "Find in document": "문서에서 찾기",
            "Match case": "경기 사례",
            "Apply": "대다",
            "GoToPage": "페이지로 이동",
            "No matches": "뷰어가 문서 검색을 완료했습니다. 더 이상 일치하는 항목이 없습니다",
            "No Text Found": "텍스트를 찾을 수 없습니다",
            "Undo": "실행 취소",
            "Redo": "다시 하다",
            "Annotation": "주석 추가 또는 편집",
            "Highlight": "텍스트 강조",
            "Underline": "밑줄 텍스트",
            "Strikethrough": "취소 선 텍스트",
            "Delete": "주석 삭제",
            "Opacity": "불투명",
            "Color edit": "색 변경",
            "Opacity edit": "불투명도 변경",
            "Highlight context": "가장 밝은 부분",
            "Underline context": "밑줄",
            "Strikethrough context": "파업",
            "Server error": "웹 서비스가 듣고 있지 않습니다. PDF 뷰어는 모든 기능을 웹 서비스에 의존합니다. 계속하려면 웹 서비스를 시작하십시오.",
            "Open text": "열다",
            "First text": "첫 페이지",
            "Previous text": "이전 페이지",
            "Next text": "다음 페이지",
            "Last text": "마지막 페이지",
            "Zoom in text": "확대",
            "Zoom out text": "축소",
            "Selection text": "선택",
            "Pan text": "팬",
            "Print text": "인쇄",
            "Search text": "검색",
            "Annotation Edit text": "주석 편집",
            "Line Thickness": "선 두께",
            "Line Properties": "선 속성",
            "Start Arrow": "시작 화살표",
            "End Arrow": "끝 화살표",
            "Line Style": "선 스타일",
            "Fill Color": "채우기 색상",
            "Line Color": "선 색",
            "None": "없음",
            "Open Arrow": "열다",
            "Closed Arrow": "닫은",
            "Round Arrow": "일주",
            "Square Arrow": "광장",
            "Diamond Arrow": "다이아몬드",
            "Butt": "대상",
            "Cut": "절단",
            "Paste": "풀",
            "Delete Context": "지우다",
            "Properties": "속성",
            "Add Stamp": "스탬프 추가",
            "Add Shapes": "도형 추가",
            "Stroke edit": "획 색상 변경",
            "Change thickness": "테두리 두께 변경",
            "Add line": "라인 추가",
            "Add arrow": "화살표 추가",
            "Add rectangle": "사각형 추가",
            "Add circle": "서클 추가",
            "Add polygon": "다각형 추가",
            "Add Comments": "의견 추가",
            "Comments": "코멘트",
            "No Comments Yet": "아직 코멘트가 없습니다",
            "Accepted": "받아 들여",
            "Completed": "완료",
            "Cancelled": "취소 된",
            "Rejected": "거부",
            "Leader Length": "리더 길이",
            "Scale Ratio": "스케일 비율",
            "Calibrate": "교정",
            "Calibrate Distance": "거리 보정",
            "Calibrate Perimeter": "주변 보정",
            "Calibrate Area": "면적 교정",
            "Calibrate Radius": "반경 보정",
            "Calibrate Volume": "볼륨 보정",
            "Depth": "깊이",
            "Closed": "닫은",
            "Round": "일주",
            "Square": "광장",
            "Diamond": "다이아몬드",
            "Edit": "편집하다",
            "Comment": "논평",
            "Comment Panel": "댓글 패널",
            "Set Status": "상태 설정",
            "Post": "게시하다",
            "Page": "페이지",
            "Add a comment": "코멘트를 추가하다",
            "Add a reply": "답글 추가",
            "Import Annotations": "JSON 파일에서 주석 가져 오기",
            "Export Annotations": "주석을 JSON 파일로 내보내기",
            "Export XFDF": "XFDF 파일로 주석 내보내기",
            "Import XFDF": "XFDF 파일에서 주석 가져 오기",
            "Add": "더하다",
            "Clear": "맑은",
            "Bold": "굵게",
            "Italic": "이탤릭체",
            "Strikethroughs": "취소 선",
            "Underlines": "밑줄",
            "Superscript": "위에 쓴",
            "Subscript": "아래 첨자",
            "Align left": "왼쪽 정렬",
            "Align right": "오른쪽 정렬",
            "Center": "센터",
            "Justify": "신이 옳다고 하다",
            "Font color": "글자 색",
            "Text Align": "텍스트 정렬",
            "Text Properties": "글꼴 스타일",
            "SignatureFieldDialogHeaderText": "서명 추가",
            "HandwrittenSignatureDialogHeaderText": "서명 추가",
            "InitialFieldDialogHeaderText": "이니셜 추가",
            "HandwrittenInitialDialogHeaderText": "이니셜 추가",
            "Draw Ink": "잉크 그리기",
            "Create": "창조하다",
            "Font family": "글꼴 군",
            "Font size": "글꼴 크기",
            "Free Text": "무료 문자",
            "Import Failed": "잘못된 JSON 파일 유형 또는 파일 이름입니다. 유효한 JSON 파일을 선택하십시오",
            "Import PDF Failed": "잘못된 PDF 파일 형식 또는 PDF 파일이 존재하지 않습니다. 유효한 PDF 파일을 선택하십시오",
            "File not found": "가져온 JSON 파일이 원하는 위치에 없습니다.",
            "Export Failed": "주석 내보내기 작업이 실패했습니다. 주석이 제대로 추가되었는지 확인하십시오.",
            "of": "의",
            "Dynamic": "동적",
            "Standard Business": "표준 비즈니스",
            "Sign Here": "여기에 서명",
            "Custom Stamp": "맞춤 스탬프",
            "Enter Signature as Name": "당신의 이름을 입력",
            "Draw-hand Signature": "무승부",
            "Type Signature": "유형",
            "Upload Signature": "업로드",
            "Browse Signature Image": "검색",
            "Save Signature": "서명 저장"
        },
        "documenteditor": {
            "Table": "표",
            "Row": "열",
            "Cell": "세포",
            "Ok": "확인",
            "Cancel": "취소",
            "Size": "크기",
            "Preferred Width": "선호하는 폭",
            "Points": "포인트들",
            "Percent": "퍼센트",
            "Measure in": "측정",
            "Alignment": "조정",
            "Left": "왼쪽",
            "Center": "센터",
            "Right": "권리",
            "Justify": "신이 옳다고 하다",
            "Indent from left": "왼쪽에서 들여 쓰기",
            "Borders and Shading": "테두리와 음영",
            "Options": "옵션",
            "Specify height": "높이 지정",
            "At least": "적어도",
            "Exactly": "바로 그거죠",
            "Row height is": "행 높이",
            "Allow row to break across pages": "행이 여러 페이지에서 나뉘도록 허용",
            "Repeat as header row at the top of each page": "각 페이지 상단에 헤더 행으로 반복",
            "Vertical alignment": "수직 정렬",
            "Top": "상단",
            "Bottom": "바닥",
            "Default cell margins": "기본 셀 여백",
            "Default cell spacing": "기본 셀 간격",
            "Allow spacing between cells": "셀 간 간격 허용",
            "Cell margins": "셀 마진",
            "Same as the whole table": "전체 테이블과 동일",
            "Borders": "테두리",
            "None": "없음",
            "Style": "스타일",
            "Width": "폭",
            "Height": "신장",
            "Letter": "편지",
            "Tabloid": "정제",
            "Legal": "적법한",
            "Statement": "성명서",
            "Executive": "행정부",
            "A3": "A3",
            "A4": "A4",
            "A5": "A5",
            "B4": "B4",
            "B5": "B5",
            "Custom Size": "사용자 정의 크기",
            "Different odd and even": "다른 홀수 및 짝수",
            "Different first page": "다른 첫 페이지",
            "From edge": "가장자리에서",
            "Header": "머리글",
            "Footer": "보행인",
            "First Page Header": "첫 페이지 머리글",
            "First Page Footer": "첫 페이지 바닥글",
            "Even Page Header": "짝수 페이지 머리글",
            "Even Page Footer": "짝수 페이지 바닥글",
            "Odd Page Header": "홀수 페이지 헤더",
            "Odd Page Footer": "홀수 페이지 바닥글",
            "Margin": "여백",
            "Paper": "종이",
            "Layout": "나열한 것",
            "Orientation": "정위",
            "Landscape": "경치",
            "Portrait": "초상화",
            "Show page numbers": "페이지 번호 표시",
            "Right align page numbers": "페이지 번호를 오른쪽 정렬",
            "Nothing": "아무것도",
            "Tab leader": "탭 리더",
            "Show levels": "레벨 표시",
            "Use hyperlinks instead of page numbers": "페이지 번호 대신 하이퍼 링크 사용",
            "Build table of contents from": "목차 구축",
            "Styles": "스타일",
            "Available styles": "사용 가능한 스타일",
            "TOC level": "TOC 레벨",
            "Heading": "표제",
            "Heading 1": "제목 1",
            "Heading 2": "제목 2",
            "Heading 3": "제목 3",
            "Heading 4": "제목 4",
            "Heading 5": "제목 5",
            "Heading 6": "제목 6",
            "List Paragraph": "단락 목록",
            "Normal": "정상",
            "Outline levels": "개요 수준",
            "Table entry fields": "테이블 입력 필드",
            "Modify": "수정",
            "Color": "색깔",
            "Setting": "환경",
            "Box": "상자",
            "All": "모두",
            "Custom": "커스텀",
            "Preview": "시사",
            "Shading": "농담",
            "Fill": "가득 따르다",
            "Apply To": "적용하다",
            "Table Properties": "테이블 속성",
            "Cell Options": "셀 옵션",
            "Table Options": "테이블 옵션",
            "Insert Table": "테이블 삽입",
            "Number of columns": "열 수",
            "Number of rows": "행 수",
            "Text to display": "표시 할 텍스트",
            "ScreenTip text": "스크린팁 텍스트",
            "Address": "주소",
            "Insert Hyperlink": "하이퍼 링크 삽입",
            "Edit Hyperlink": "하이퍼 링크 편집",
            "Insert": "끼워 넣다",
            "General": "일반",
            "Indentation": "들여 쓰기",
            "Before text": "텍스트 전에",
            "Special": "특별한",
            "First line": "첫 번째 줄",
            "Hanging": "교수형",
            "After text": "텍스트 후",
            "By": "으로",
            "Before": "전에",
            "Line Spacing": "줄 간격",
            "After": "후",
            "At": "에서",
            "Multiple": "배수",
            "Spacing": "간격",
            "Define new Multilevel list": "새로운 다단계 목록 정의",
            "List level": "리스트 레벨",
            "Choose level to modify": "수정할 레벨을 선택하십시오",
            "Level": "수평",
            "Number format": "숫자 형식",
            "Number style for this level": "이 레벨의 숫자 스타일",
            "Enter formatting for number": "숫자 형식을 입력하십시오",
            "Start at": "에서 시작",
            "Restart list after": "다음에 다시 시작 목록",
            "Position": "위치",
            "Text indent at": "텍스트 들여 쓰기",
            "Aligned at": "에 정렬",
            "Follow number with": "와 번호를 따르십시오",
            "Tab character": "탭 문자",
            "Space": "우주",
            "Arabic": "아라비아 말",
            "UpRoman": "로마",
            "LowRoman": "로로 만",
            "UpLetter": "편지",
            "LowLetter": "낮은 글자",
            "Number": "번호",
            "Leading zero": "선행 제로",
            "Bullet": "총알",
            "Ordinal": "서수",
            "Ordinal Text": "서수 텍스트",
            "For East": "동쪽",
            "No Restart": "다시 시작하지 않음",
            "Font": "폰트",
            "Font style": "폰트 스타일",
            "Underline style": "밑줄 스타일",
            "Font color": "글자 색",
            "Effects": "효과",
            "Strikethrough": "취소 선",
            "Superscript": "어깨 문자",
            "Subscript": "아래 첨자",
            "Double strikethrough": "이중 취소 선",
            "Regular": "정규병",
            "Bold": "굵게",
            "Italic": "이탤릭체",
            "Cut": "절단",
            "Copy": "부",
            "Paste": "풀",
            "Hyperlink": "하이퍼 링크",
            "Open Hyperlink": "하이퍼 링크 열기",
            "Copy Hyperlink": "하이퍼 링크 복사",
            "Remove Hyperlink": "하이퍼 링크 제거",
            "Paragraph": "절",
            "Linked Style": "연결 (문단 및 문자)",
            "Character": "캐릭터",
            "Merge Cells": "셀 병합",
            "Insert Above": "위에 삽입",
            "Insert Below": "아래에 삽입",
            "Insert Left": "왼쪽 삽입",
            "Insert Right": "오른쪽 삽입",
            "Delete": "지우다",
            "Delete Table": "테이블 삭제",
            "Delete Row": "행 삭제",
            "Delete Column": "열 삭제",
            "File Name": "파일 이름",
            "Format Type": "체재 유형",
            "Save": "저장",
            "Navigation": "항해",
            "Results": "결과",
            "Replace": "바꾸다",
            "Replace All": "전부 교체",
            "We replaced all": "우리는 모두 교체",
            "Find": "찾기",
            "No matches": "맞지 않는다",
            "All Done": "모두 완료",
            "Result": "결과",
            "of": "의",
            "instances": "인스턴스",
            "with": "와",
            "Click to follow link": "링크를 클릭하십시오",
            "Continue Numbering": "번호 매기기 계속",
            "Bookmark name": "북마크 이름",
            "Close": "닫기",
            "Restart At": "다시 시작",
            "Properties": "속성",
            "Name": "이름",
            "Style type": "스타일 타입",
            "Style based on": "기반 스타일",
            "Style for following paragraph": "다음 단락 스타일",
            "Formatting": "서식",
            "Numbering and Bullets": "번호 매기기 및 글 머리 기호",
            "Numbering": "넘버링",
            "Update Field": "업데이트 필드",
            "Edit Field": "필드 편집",
            "Bookmark": "서표",
            "Page Setup": "페이지 설정",
            "No bookmarks found": "북마크를 찾을 수 없습니다",
            "Number format tooltip information": "단일 수준 숫자 형식 : </br> [PREFIX] % [LEVELNUMBER] [SUFFIX] </br> 예 : 'Chapter % 1' 1 장 항목 </br> 2 장 항목 </br>… </br> N. 항목 </br> </br> </br> 다단계 번호 형식 : </br> [ PREFIX] % [LEVELNUMBER] [SUFFIX] + [PREFIX] % [LEVELNUMBER] [SUFFIX] </br> 예 : '% 1. % 2' 1.1과 같은 번호가 표시됩니다. 항목 </br> 1.2. 항목 </br>… </br> 1.N. 안건",
            "Format": "체재",
            "Create New Style": "새로운 스타일 만들기",
            "Modify Style": "스타일 수정",
            "New": "새로운",
            "Bullets": "글 머리 기호",
            "Use bookmarks": "북마크 사용",
            "Table of Contents": "목차",
            "AutoFit": "자동 맞춤",
            "AutoFit to Contents": "내용에 자동 맞춤",
            "AutoFit to Window": "창에 자동 맞춤",
            "Fixed Column Width": "고정 열 너비",
            "Reset": "초기화",
            "Match case": "경기 사례",
            "Whole words": "전체 단어",
            "Add": "더하다",
            "Go To": "이동",
            "Search for": "검색",
            "Replace with": "로 교체",
            "TOC 1": "목차 1",
            "TOC 2": "목차 2",
            "TOC 3": "목차 3",
            "TOC 4": "목차 4",
            "TOC 5": "목차 5",
            "TOC 6": "목차 6",
            "TOC 7": "목차 7",
            "TOC 8": "목차 8",
            "TOC 9": "목차 9",
            "Right-to-left": "오른쪽에서 왼쪽으로",
            "Left-to-right": "왼쪽에서 오른쪽으로",
            "Direction": "방향",
            "Table direction": "테이블 방향",
            "Indent from right": "오른쪽에서 들여 쓰기",
            "Contextual Spacing": "같은 스타일의 단락 사이에 공백을 추가하지 마십시오",
            "Password Mismatch": "비밀번호가 일치하지 않습니다",
            "Restrict Editing": "편집 제한",
            "Formatting restrictions": "포맷 제한",
            "Allow formatting": "서식 허용",
            "Editing restrictions": "편집 제한",
            "Read only": "읽기 전용",
            "Exceptions Optional": "예외 (선택 사항)",
            "Select Part Of Document And User": "문서의 일부를 선택하고 자유롭게 편집 할 수있는 사용자를 선택하십시오.",
            "Everyone": "여러분",
            "More users": "더 많은 사용자",
            "Add Users": "사용자 추가",
            "Enforcing Protection": "예, 보호 시작",
            "Start Enforcing Protection": "보호 시작",
            "Enter User": "사용자 입력",
            "Users": "사용자",
            "Enter new password": "새 비밀번호를 입력하십시오",
            "Reenter new password to confirm": "확인을 위해 새 비밀번호를 다시 입력하십시오",
            "Your permissions": "당신의 권한",
            "Protected Document": "이 문서는 의도하지 않은 편집으로부터 보호됩니다.이 지역에서 편집 할 수 있습니다.",
            "FormFieldsOnly": "이 지역에서만 양식을 작성할 수 있습니다.",
            "CommentsOnly": "이 영역에만 주석을 삽입할 수 있습니다.",
            "ReadOnlyProtection": "이 영역에서 편집할 수 있습니다.",
            "Stop Protection": "보호 중지",
            "Password": "암호",
            "Spelling Editor": "맞춤법 편집기",
            "Spelling": "철자",
            "Spell Check": "맞춤법 검사",
            "Underline errors": "밑줄 오류",
            "Ignore": "무시",
            "Ignore All": "전부다 무시해라",
            "Add to Dictionary": "사전에 추가",
            "Change": "변화",
            "Change All": "모두 변경",
            "Suggestions": "제안",
            "The password is incorrect": "비밀번호가 잘못되었습니다",
            "Error in establishing connection with web server": "웹 서버와의 연결 설정 중 오류",
            "Highlight the regions I can edit": "편집 할 수있는 영역을 강조 표시합니다",
            "Show All Regions I Can Edit": "편집 할 수있는 모든 지역 표시",
            "Find Next Region I Can Edit": "편집 할 수있는 다음 지역 찾기",
            "Keep source formatting": "소스 형식 유지",
            "Match destination formatting": "대상 형식 일치",
            "Text only": "텍스트 만",
            "Comments": "코멘트",
            "Type your comment": "귀하의 의견을 입력하십시오",
            "Post": "우편",
            "Reply": "댓글",
            "New Comment": "새로운 코멘트",
            "Edit": "편집하다",
            "Resolve": "결의",
            "Reopen": "다시 열다",
            "No comments in this document": "이 문서에 의견이 없습니다",
            "more": "더",
            "Type your comment here": "여기에 의견을 입력하십시오",
            "Next Comment": "다음 댓글",
            "Previous Comment": "이전 의견",
            "Un-posted comments": "게시되지 않은 댓글",
            "Discard Comment": "댓글이 게시되지 않았습니다. 계속하면 해당 댓글이 삭제됩니다.",
            "Discard": "버리다",
            "No Headings": "제목이 없습니다!",
            "Add Headings": "이 문서에는 제목이 없습니다. 제목을 추가하고 다시 시도하십시오.",
            "More Options": "추가 옵션",
            "Click to see this comment": "이 의견을 보려면 클릭하십시오",
            "Drop Down Form Field": "드롭 다운 양식 필드",
            "Drop-down items": "드롭 다운 항목",
            "Items in drop-down list": "드롭 다운 목록의 항목",
            "ADD": "더하다",
            "REMOVE": "없애다",
            "Field settings": "필드 설정",
            "Tooltip": "툴팁",
            "Drop-down enabled": "드롭 다운 사용",
            "Check Box Form Field": "확인란 양식 필드",
            "Check box size": "확인란 크기",
            "Auto": "자동",
            "Default value": "기본값",
            "Not checked": "확인되지 않은",
            "Checked": "확인",
            "Check box enabled": "확인란 활성화",
            "Text Form Field": "텍스트 양식 필드",
            "Type": "유형",
            "Default text": "기본 텍스트",
            "Maximum length": "최대 길이",
            "Text format": "텍스트 형식",
            "Fill-in enabled": "필인 사용",
            "Default number": "기본 번호",
            "Default date": "기본 날짜",
            "Date format": "날짜 형식",
            "Merge Track": "이 조치는 변경으로 표시되지 않습니다. 계속 하시겠습니까?",
            "UnTrack": "추적 할 수 없습니다",
            "Accept": "동의하기",
            "Reject": "받지 않다",
            "Previous Changes": "이전 변경",
            "Next Changes": "다음 변경",
            "Inserted": "삽입",
            "Deleted": "삭제",
            "Changes": "변경",
            "Accept all": "모든것을 받아 들인다",
            "Reject all": "모두 거부",
            "No changes": "변경 사항 없음",
            "Accept Changes": "변경 수락",
            "Reject Changes": "변경 사항 거부",
            "User": "사용자",
            "View": "전망",
            "Insertion": "삽입",
            "Deletion": "삭제",
            "All caps": "모든 대문자",
            "This region is locked by": "이 지역은",
            "Lock": "자물쇠",
            "Unlock": "터놓다",
            "Already locked": "선택되었거나 지역의 일부가 이미 다른 사용자에 의해 잠겼습니다.",
            "1, 2, 3, ...": "1, 2, 3, ...",
            "a, b, c, ...": "a, b, c, ...",
            "A, B, C, ...": "A, B, C, ...",
            "I, II, III, ...": "나, II, III, ...",
            "i, ii, iii, ...": "i, ii, iii, ...",
            "Text Form": "텍스트 양식",
            "Check Box": "체크 박스",
            "DropDown": "쓰러지 다",
            "Form Fields": "양식 필드",
            "Fillin enabled": "채우기 사용",
            "Multiple Comment": "귀하의 의견을 게시하십시오",
            "InsertFootnote": "삽입 각주",
            "InsertEndnote": "삽입 미주",
            "Footnote": "각주",
            "Endnote": "미주",
            "Click to View/Edit Footnote": "각주를 보거나 편집하려면 클릭하십시오.",
            "Click to View/Edit Endnote": "미주를 보거나 편집하려면 클릭하십시오.",
            "InsertAsRows": "새 행으로 삽입",
            "InsertAsColumns": "새 열로 삽입",
            "OverwriteCells": "셀 덮어 쓰기",
            "NestTable": "네스트 테이블",
            "No suggestions": "제안 없음",
            "More Suggestion": "더 많은 제안",
            "Ignore Once": "한 번 무시",
            "Keep With Next": "다음으로 유지",
            "Keep Lines Together": "줄을 함께 유지",
            "WidowControl": "과부/고아 통제",
            "Indents and Spacing": "들여쓰기 및 간격",
            "Line and Page Breaks": "줄 및 페이지 나누기",
            "Pagination": "쪽수 매기기",
            "Single": "하나의",
            "DashSmallGap": "DashSmallGap",
            "DashDot": "대시닷",
            "Double": "더블",
            "ThinThickSmallGap": "얇은두꺼운작은갭",
            "ThickThinSmallGap": "두껍게얇게작은갭",
            "ThickThinMediumGap": "두껍게얇게중간갭",
            "ThickThinLargeGap": "두껍게얇게큰갭",
            "SingleWavy": "싱글웨이비",
            "DoubleWavy": "더블웨이비",
            "Inset": "삽입",
            "DashLargeGap": "대시큰간격",
            "Dot": "점",
            "DashDotDot": "대시닷닷",
            "Triple": "삼루타",
            "ThinThickThinSmallGap": "가늘게 굵게 가늘게 작은 갭",
            "ThinThickThinMediumGap": "얇은두꺼움얇은중간갭",
            "ThinThickThinLargeGap": "가늘게 굵게 가늘게 큰 갭",
            "DashDotStroked": "대시닷스트로크",
            "Engrave3D": "새기다3D",
            "Thick": "두꺼운",
            "Outset": "시초",
            "Emboss3D": "엠보스3D",
            "ThinThickLargeGap": "얇은두꺼운대형갭",
            "ThinThickMediumGap": "얇은두꺼운중간갭",
            "Number of rows must be between 1 and 32767.": "행 수는 1에서 32767 사이여야 합니다.",
            "Number of columns must be between 1 and 63.": "열 수는 1에서 63 사이여야 합니다.",
            "Unlimited": "제한 없는",
            "Regular text": "일반 텍스트",
            "Date": "날짜",
            "Uppercase": "대문자",
            "Lowercase": "소문자",
            "FirstCapital": "퍼스트캐피탈",
            "TitleCase": "제목 케이스",
            "Filling in forms": "양식 작성",
            "px": "픽셀",
            "Tracked changes": "추적된 변경 사항",
            "TrackChangesOnly": "이 지역에서 편집할 수 있지만 모든 변경 사항은 추적됩니다.",
            "RemovedIgnore": "무시된 예외를 제거하시겠습니까?",
            "RemovedIgnoreExceptions": "문서 보호에서 이렇게 변경하면 Word에서 이 문서의 모든 예외를 무시합니다.",
            "Information": "정보.",
            "Yes": "예",
            "No": "아니",
            "Page Break": "페이지 나누기",
            "Column Break": "단 나누기",
            "Section Break Next Page": "섹션 나누기(다음 페이지)",
            "Section Break Continuous": "구역 나누기(연속)",
            "Unsupported format": "선택한 파일 형식이 지원되지 않습니다. 유효한 형식을 선택하십시오.",
            "One": "하나",
            "Two": "둘",
            "Three": "삼",
            "Presets": "프리셋",
            "Columns": "열",
            "Split your text into two or more columns": "텍스트를 두 개 이상의 열로 분할",
            "Line between column": "열 사이의 선",
            "Width and Spacing": "폭과 간격",
            "Equal column width": "동일한 열 너비",
            "Column": "열",
            "Paste Content Dialog": "Due to browser’s security policy, paste from system clipboard is restricted. Alternatively use the keyboard shortcut",
            "Paste CheckBox Content Dialog": "Don't show again",
            "The top/bottom margins are too large for the page height in some sections.": "The top/bottom margins are too large for the page height in some sections.",
            "Column width cannot be less than 36 pt.": "Column width cannot be less than 36 pt.",
            "Left and right margins.": "Settings you chose for the left and right margins, column spacing, or pargraph indents are too large for the page width in same secitions.",
            "You may format text only with certain styles": "특정 스타일로만 텍스트 서식을 지정할 수 있습니다.",
            "Ignore all": "전부다 무시해라"
        },
        "documenteditorcontainer": {
            "New": "새로운",
            "Open": "열다",
            "Undo": "실행 취소",
            "Redo": "다시 하다",
            "Image": "영상",
            "Table": "표",
            "Link": "링크",
            "Bookmark": "서표",
            "Table of Contents": "목차",
            "HEADING - - - - 1": "머리말----1",
            "HEADING - - - - 2": "머리말----2",
            "HEADING - - - - 3": "머리말----3",
            "Header": "머리글",
            "Footer": "보행인",
            "Page Setup": "페이지 설정",
            "Page Number": "페이지 번호",
            "Break": "단절",
            "Find": "찾기",
            "Local Clipboard": "로컬 클립 보드",
            "Restrict Editing": "편집 제한",
            "Upload from computer": "컴퓨터에서 업로드",
            "By URL": "URL로",
            "Page Break": "페이지 나누기",
            "Section Break": "섹션 나누기",
            "Header And Footer": "머리글 및 바닥 글",
            "Options": "옵션",
            "Levels": "레벨",
            "Different First Page": "다른 첫 페이지",
            "Different header and footer for odd and even pages": "홀수 및 짝수 페이지를위한 다른 머리글 및 바닥 글.",
            "Different Odd And Even Pages": "다른 홀수 및 짝수 페이지",
            "Different header and footer for first page": "첫 페이지에 대한 다른 머리글 및 바닥 글",
            "Position": "위치",
            "Header from Top": "상단에서 헤더",
            "Footer from Bottom": "바닥 글에서 바닥 글",
            "Distance from top of the page to top of the header": "페이지 상단에서 헤더 상단까지의 거리입니다.",
            "Distance from bottom of the page to bottom of the footer": "페이지 하단에서 바닥 글 하단까지의 거리입니다.",
            "Aspect ratio": "종횡비",
            "W": "여",
            "H": "H",
            "Width": "폭",
            "Height": "신장",
            "Text": "본문",
            "Paragraph": "절",
            "Fill": "가득 따르다",
            "Fill color": "채우기 색상",
            "Border Style": "테두리 스타일",
            "Outside borders": "외부 테두리",
            "All borders": "모든 테두리",
            "Inside borders": "내부 테두리",
            "Left border": "왼쪽 테두리",
            "Inside vertical border": "내부 세로 테두리",
            "Right border": "오른쪽 테두리",
            "Top border": "상단 테두리",
            "Inside horizontal border": "내부 가로 테두리",
            "Bottom border": "아래쪽 테두리",
            "Border color": "테두리 색",
            "Border width": "테두리 너비",
            "Cell": "세포",
            "Merge cells": "셀 병합",
            "Insert Or Delete": "삽입 / 삭제",
            "Insert columns to the left": "왼쪽에 열 삽입",
            "Insert columns to the right": "오른쪽에 열 삽입",
            "Insert rows above": "위의 행 삽입",
            "Insert rows below": "아래에 행 삽입",
            "Delete rows": "행 삭제",
            "Delete columns": "열 삭제",
            "Cell Margin": "세포 여백",
            "Top": "상단",
            "Bottom": "바닥",
            "Left": "왼쪽",
            "Right": "권리",
            "Align Text": "텍스트 정렬",
            "Align top": "상단 정렬",
            "Align bottom": "하단 정렬",
            "Align center": "가운데 정렬",
            "Number of heading or outline levels to be shown in table of contents": "목차에 표시 할 제목 또는 개요 수준의 수입니다.",
            "Show page numbers": "페이지 번호 표시",
            "Show page numbers in table of contents": "목차에 페이지 번호를 표시합니다.",
            "Right align page numbers": "페이지 번호를 오른쪽 정렬",
            "Right align page numbers in table of contents": "목차에서 페이지 번호를 오른쪽 정렬하십시오.",
            "Use hyperlinks": "하이퍼 링크 사용",
            "Use hyperlinks instead of page numbers": "페이지 번호 대신 하이퍼 링크를 사용하십시오.",
            "Font": "폰트",
            "Font Size": "폰트 크기",
            "Font color": "글자 색",
            "Text highlight color": "텍스트 하이라이트 색상",
            "Clear all formatting": "모든 서식 지우기",
            "Bold Tooltip": "굵게 (Ctrl + B)",
            "Italic Tooltip": "기울임 꼴 (Ctrl + I)",
            "Underline Tooltip": "밑줄 (Ctrl + U)",
            "Strikethrough": "취소 선",
            "Superscript Tooltip": "위첨자 (Ctrl + Shift ++)",
            "Subscript Tooltip": "아래 첨자 (Ctrl + =)",
            "Align left Tooltip": "왼쪽 맞춤 (Ctrl + L)",
            "Center Tooltip": "가운데 (Ctrl + E)",
            "Align right Tooltip": "오른쪽 맞춤 (Ctrl + R)",
            "Justify Tooltip": "정당화 (Ctrl + J)",
            "Decrease indent": "들여 쓰기 감소",
            "Increase indent": "들여 쓰기",
            "Line spacing": "줄 간격",
            "Bullets": "글 머리 기호",
            "Numbering": "넘버링",
            "Styles": "스타일",
            "Manage Styles": "스타일 관리",
            "Page": "페이지",
            "of": "의",
            "Fit one page": "한 페이지에 적합",
            "Spell Check": "맞춤법 검사",
            "Underline errors": "밑줄 오류",
            "Fit page width": "페이지 너비 맞추기",
            "Update": "최신 정보",
            "Cancel": "취소",
            "Insert": "끼워 넣다",
            "No Border": "국경 없음",
            "Create a new document": "새 문서를 작성하십시오.",
            "Open a document": "문서를여십시오.",
            "Undo Tooltip": "마지막 작업을 취소합니다 (Ctrl + Z).",
            "Redo Tooltip": "마지막 작업을 다시 실행하십시오 (Ctrl + Y).",
            "Insert inline picture from a file": "파일에서 인라인 그림을 삽입하십시오.",
            "Insert a table into the document": "문서에 테이블 삽입",
            "Create Hyperlink": "웹 페이지 및 파일에 빠르게 액세스 할 수 있도록 문서에 링크를 만듭니다 (Ctrl + K).",
            "Insert a bookmark in a specific place in this document": "이 문서의 특정 위치에 책갈피를 삽입하십시오.",
            "Provide an overview of your document by adding a table of contents": "목차를 추가하여 문서의 개요를 제공하십시오.",
            "Add or edit the header": "헤더를 추가하거나 편집하십시오.",
            "Add or edit the footer": "바닥 글을 추가하거나 편집하십시오.",
            "Open the page setup dialog": "페이지 설정 대화 상자를 엽니 다.",
            "Add page numbers": "페이지 번호를 추가하십시오.",
            "Find Text": "문서에서 텍스트를 찾으십시오 (Ctrl + F).",
            "Toggle between the internal clipboard and system clipboard": "내부 클립 보드와 시스템 클립 보드간에 전환합니다. </br> 브라우저 보안 정책으로 인해 스크립트를 통한 시스템 클립 보드 액세스가 거부되었습니다. 대신 </br> 1. 내부 클립 보드를 사용하여 구성 요소 내에서 잘라 내기, 복사 및 붙여 넣기를 수행 할 수 있습니다. </br> 2. 키보드 단축키 (Ctrl + X, Ctrl + C 및 Ctrl + V)를 사용하여 잘라낼 수 있습니다. , 시스템 클립 보드로 복사하여 붙여 넣기.",
            "Current Page Number": "문서의 현재 페이지 번호 특정 페이지를 탐색하려면 클릭하거나 탭하십시오.",
            "Read only": "읽기 전용",
            "Protections": "보호",
            "Error in establishing connection with web server": "웹 서버와의 연결 설정 중 오류",
            "Single": "단일",
            "Double": "더블",
            "New comment": "새로운 의견",
            "Comments": "코멘트",
            "Print layout": "인쇄 레이아웃",
            "Web layout": "웹 레이아웃",
            "Text Form": "텍스트 양식",
            "Check Box": "확인란",
            "DropDown": "쓰러지 다",
            "Update Fields": "업데이트 필드",
            "Update cross reference fields": "상호 참조 필드 업데이트",
            "Hide properties pane": "속성 창 숨기기",
            "Show properties pane": "속성 창 표시",
            "Form Fields": "양식 필드",
            "Track Changes": "문서의 변경 사항을 추적합니다.",
            "TrackChanges": "변경 사항 추적",
            "AllCaps": "AllCaps",
            "Change case Tooltip": "케이스 변경",
            "Insert Footnote": "각주 삽입",
            "Insert Endnote": "미주 삽입",
            "Footnote Tooltip": "각주 삽입 (Alt + Ctrl + F).",
            "Endnote Tooltip": "미주 삽입 (Alt + Ctrl + D).",
            "UPPERCASE": "대문자",
            "No color": "색상 없음",
            "Top margin": "상단 여백",
            "Bottom margin": "하단 여백",
            "Left margin": "왼쪽 여백",
            "Right margin": "오른쪽 여백",
            "Normal": "정상",
            "Heading": "표제",
            "Heading 1": "제목 1",
            "Heading 2": "제목 2",
            "Heading 3": "제목 3",
            "Heading 4": "제목 4",
            "Heading 5": "제목 5",
            "Heading 6": "제목 6",
            "ZoomLevelTooltip": "줌 레벨. 클릭하거나 탭하여 확대/축소 옵션을 엽니다.",
            "None": "없음",
            "Borders": "테두리",
            "ShowHiddenMarks Tooltip": "공백, 탭, 단락 기호 및 나누기와 같은 숨겨진 문자를 표시합니다.(Ctrl + *)",
            "Columns": "열",
            "Column Break": "단 나누기"
        },
        "carousel": {
            "nextSlide": "다음 슬라이드",
            "of": "~의",
            "pauseSlideTransition": "슬라이드 전환 일시 중지",
            "playSlideTransition": "슬라이드 전환 재생",
            "previousSlide": "이전 슬라이드",
            "slide": "미끄러지 다",
            "slideShow": "슬라이드 쇼"
        },
        "message": {
            "close": "닫다"
        },
        "image-editor": {
            "Crop": "수확고",
            "ZoomIn": "확대",
            "ZoomOut": "축소",
            "Undo": "Undo",
            "Redo": "Redo",
            "Transform": "변환",
            "Annotation": "주석",
            "Finetune": "미세 조정",
            "Brightness": "명도",
            "Contrast": "차이",
            "Hue": "색조",
            "Saturation": "포화",
            "Opacity": "불투명",
            "Blur": "흐림",
            "Sharpen": "Sharpen",
            "Exposure": "노출",
            "Filter": "필터",
            "Default": "기본",
            "Chrome": "크롬",
            "Cold": "춥다",
            "Warm": "따뜻한",
            "Grayscale": "그레이스케일",
            "BlackAndWhite": "Black and White",
            "Sepia": "세피아",
            "Invert": "거꾸로 하다",
            "Text": "텍스트 추가",
            "Pen": "펜",
            "Reset": "초기화",
            "Save": "구하다",
            "Select": "고르다",
            "RotateLeft": "왼쪽으로 회전",
            "RotateRight": "오른쪽으로 회전",
            "HorizontalFlip": "수평 뒤집기",
            "VerticalFlip": "수직 뒤집기",
            "OK": "확인",
            "Cancel": "취소",
            "FillColor": "채우기 색상",
            "StrokeColor": "획 색상",
            "StrokeWidth": "획 폭",
            "FontFamily": "글꼴 모음",
            "FontStyle": "글꼴 스타일",
            "FontSize": "글꼴 크기",
            "FontColor": "글꼴 색상",
            "Pan": "팬",
            "Move": "이동하다",
            "Custom": "관습",
            "Square": "정사각형",
            "Circle": "원",
            "Rectangle": "직사각형",
            "Line": "선",
            "Arrow": "Arrow",
            "Bold": "용감한",
            "Italic": "이탤릭체",
            "BoldItalic": "굵은 기울임꼴",
            "XSmall": "X-스몰",
            "Small": "작은",
            "Medium": "중간",
            "Large": "크기가 큰",
            "XLarge": "X-대형",
            "ABC": "알파벳",
            "Browse": "검색",
            "Duplicate": "복제하다",
            "Remove": "제거하다",
            "EditText": "Edit Text"
        },
        "mention": {
            "noRecordsTemplate": "기록 없음"
        }
    }
}