import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NpFcvErrorComponent } from './np-fcv/np-fcv-error/np-fcv-error.component';
import { NpFcvNameDirective } from './np-fcv/np-fcv-name/np-fcv-name.directive';


@NgModule({
  declarations: [
    // forms
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NpFcvErrorComponent,
    NpFcvNameDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // forms
    NpFcvNameDirective, NpFcvErrorComponent,
  ]
})
export class NewplaFormsModule { }
