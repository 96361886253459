export abstract class NpCookieUtils {

    public static getCookie(name: string) {
        var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? value[2] : null;
    }

    public static setCookie(name: string, value: string, unixTime: number) {

        var date = new Date();
        date.setTime(date.getTime() + unixTime);
        var expires = '; expires=' + date.toUTCString();
        document.cookie = name + '=' + value + expires + '; path=/';

    }

    public static deleteCookie(name: string) {
        var date = new Date();
        date.setTime(date.getTime() - 1);
        document.cookie = name + '=;expires=' + date.toUTCString() + ';path=/';
    }

}